import React from 'react'
import StatsThree from '../components/dataVisualization/StatsThree'
import TableSortable from '../components/dataVisualization/TableSortable'

export default function Home({
  setActiveNavItem
}) {
  return (
    <div className="">
        <StatsThree/>
        <div className="py-10"></div>
        <TableSortable setActiveNavItem={setActiveNavItem}/>
    </div>
  )
}
