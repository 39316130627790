import React from 'react';
import { Box } from '@mui/material';



// Item card component
const ItemLine = ({
  margin = '1vh 10vw 3vh 10vw',
  borderBottom = '1px solid gray' 
  
}) => {
  return (
    <Box item 
      margin = {margin}
      borderBottom= {borderBottom}
    />
  );
};

export default ItemLine;
