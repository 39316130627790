import React, { useEffect, useState } from 'react'
import Loader from '../../utils/Loader';
import TextInput from '../../variableModifiers/TextInput';
import RangeSlider from '../../variableModifiers/RangeSlider';
import PositionEditor from '../../variableModifiers/PositionEditor';
import ToggleSwitch from '../../variableModifiers/ToggleSwitch';

export default function EditButtonWhatsapp(
  {
    editMasterData,
    setEditMasterData,
    index
  }
) {


  const [iconType, setIconType] = useState(false);
  const [iconBorderRadius, setIconBorderRadius] = useState(false);
  const [iconSize, setIconSize] = useState(false);
  const [margin, setMargin] = useState(false);
  const [preloadedMessage, setPrealodedMessage] = useState(false);
  const [number, setNumber] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if( editMasterData ){
      setIconType(editMasterData.buttons[index].type)
      setIconBorderRadius(editMasterData.buttons[index].iconContainerBorderRadius)
      setIconSize(editMasterData.buttons[index].iconSize)
      setMargin(editMasterData.buttons[index].margin)
      setPrealodedMessage(editMasterData.buttons[index].message)
      setNumber(editMasterData.buttons[index].number)
      setIsActive(editMasterData.buttons[index].isActive)
      setIsLoaded(true)
    }
  }, [editMasterData]);

  const handleInputChange = (event, value) => {
    const edit = editMasterData
    switch (event.target.name) {
      case 'isActive':
        setIsActive(event.target.value);
        edit.buttons[index].isActive = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'number':
        setNumber(event.target.value)
        edit.buttons[index].number = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return
      case 'message':
        setPrealodedMessage(event.target.value)
        edit.buttons[index].message = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return
      case 'iconBorder':
        setIconBorderRadius(value)
        edit.buttons[index].iconContainerBorderRadius = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'margin':
        setMargin(event.target.value)
        edit.buttons[index].margin = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return
      case 'iconSize':
        setIconSize(value)
        edit.buttons[index].iconSize = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      /* case 'valueType':
        setLogoSrc(value)
        edit.headerStyle.logoSrc = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return
      case 'eventType':
        setBackgroundColor(event.target.value)
        edit.headerStyle.backgroundColor = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return */
      default:
        console.log(event.target.name)
        return
      }
  };


  return (
    <>
    {editMasterData === undefined || !isLoaded? (
      <Loader/>
      ):(
        <>
          <ToggleSwitch
            refName="isActive"
            changeFunction={handleInputChange}
            text="Is Active"
            value={isActive}
          />
          {isActive ? (
            <>
            <RangeSlider
              refName = {"iconSize"}
              changeFunction = {handleInputChange}
              text = {"Button Size"}
              value = {iconSize}
              min = {24}
              max = {60}
              step = {1}
            />
            <TextInput
              refName={"number"}
              changeFunction={handleInputChange}
              text={"Message Number"}
              value={number}
            />
            <TextInput
              refName={"message"}
              changeFunction={handleInputChange}
              text={"Preloaded Message"}
              value={preloadedMessage}
            />
            <RangeSlider
              refName = {"iconBorder"}
              changeFunction = {handleInputChange}
              text = {"Icon Roundness"}
              value = {iconBorderRadius}
              min = {0}
              max = {100}
              step = {0.1}
            />
            <PositionEditor
              value={margin}
              refName = {"margin"}
              changeFunction={handleInputChange}
              text={"Button Position"}
            />
            </>
          ):(
            <></>
          )}
        </>
      )}
    </>
  )
}
