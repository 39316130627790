import React from 'react';
import RangeSlider from './RangeSlider';
import EditorBorder from './EditorBorder';

const WidthHeightEditor = ({
  cardHeight,
  cardRatio,
  handleInputChange,
  cardHeightMin = 1,
  cardHeightMax = 20,
  cardHeightStep = 0.1,
  cardRatioMin = 0.4,
  cardRatioMax = 5,
  cardRatioStep = 0.01,
  heightRef = "cardHeight",
  ratioRef = "cardRatio",
  heightName = "Card Height",
  ratioName = "Card Width",
  text
}) => {
  return (
    <EditorBorder text={text}>
      <div className="flex space-x-4">
        <div className="flex-1">
          <RangeSlider
            refName={heightRef}
            changeFunction={handleInputChange}
            text={heightName}
            value={cardHeight}
            min={cardHeightMin}
            max={cardHeightMax}
            step={cardHeightStep}
            external={false}
          />
        </div>
        <div className="flex-1">
          <RangeSlider
            refName={ratioRef}
            changeFunction={handleInputChange}
            text={ratioName}
            value={cardRatio}
            min={cardRatioMin}
            max={cardRatioMax}
            step={cardRatioStep}
            external={false}
          />
        </div>
      </div>
    </EditorBorder>
  );
}

export default WidthHeightEditor;
