import StatsBox from "./StatsBox"

const stats = [
  { name: 'Total Viewers', stat: '71,897' },
  { name: 'Viewers (24h)', stat: '458' },
  { name: 'Avg. User Time', stat: '7.25 min' },
]

export default function StatsThree() {
  return (
    <div>
      <h3 className="text-base font-semibold leading-6 text-gray-900">Last 30 days</h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {stats.map((item) => (
          <StatsBox key={item.name} mainText={item.name} mainNumber={item.stat}/>
        ))}
      </dl>
    </div>
  )
}