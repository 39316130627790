import React from 'react';
import MailWallService from './MailWallService'; // Adjust the import path as needed

export default function  ProjectServices(
  {
    editMasterData,
    setEditMasterData,
    handleSave
  }
){
  console.log(editMasterData)
  return (
    <div className="p-4 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">Available SAAS Options</h1>
      <div className="space-y-4">
        <MailWallService 
          editMasterData = {editMasterData}
          setEditMasterData={setEditMasterData} 
          handleSave={handleSave}
        />
        {/* Add more SAAS components here when available */}
      </div>
    </div>
  );
};
