import { styled } from '@mui/material/styles';


export const HorizontalScrollableContent = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'scroll',
  overflowY: 'hidden !important',
  whiteSpace: 'nowrap',
  width: '100%',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});


export const VerticalScrollableContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
  //overflowY: 'scroll !important',
  whiteSpace: 'normal',
  width: '100%',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});