import React, { useState, useEffect } from 'react'
import { useGetProjectData, updateProject } from '../../../api/project'

import Loader from './utils/Loader';
import { FolderIcon, BoltIcon, ChartBarIcon, QrCodeIcon, PencilSquareIcon, Cog6ToothIcon, EyeIcon, AdjustmentsHorizontalIcon} from '@heroicons/react/20/solid'
import ProjectEdit from './advancedEdit/ProjectEdit';
import ProjectHome from './projectHome/ProjectHome';
import ProjectServices from './projectServices/ProjectServices';

export default function Project({projectId}) {

  const [isLoaded, setIsLoaded] = useState(false)
  const [currentTab, setCurrentTab] = useState('Project');

  //Style"
  const { projectData } = useGetProjectData(projectId);

  const [ editMasterData, setEditMasterData] = useState(null)

  console.log(editMasterData)

  const tabs = [
    { name: 'Project', href: '#', icon: FolderIcon,  current: true },
    //{ name: 'QR code', href: '#', icon: QrCodeIcon,  current: false },
    //{ name: 'Edit', href: '#', icon: PencilSquareIcon, current: false },
    //{ name: 'Preview', href: '#', icon: EyeIcon,  current: false },
    { name: 'Edit', href: '#', icon: AdjustmentsHorizontalIcon, current: false },
    { name: 'Services', href: '#', icon: BoltIcon,  current: false },
    { name: 'Settings', href: '#', icon: Cog6ToothIcon,  current: false },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const handleSave = async (event) => {
    try {
      await updateProject(projectId, editMasterData);
      console.log("Saved");
      console.log(projectId);
    } catch (error) {
      console.error('Failed to update project:', error);
    }
  }

  const renderComponent = () => {
    switch (currentTab) {
      case 'Edit':
        return <ProjectEdit 
                  editMasterData={editMasterData} 
                  setEditMasterData={setEditMasterData} 
                  handleSave={handleSave}
                />;
      case 'Project':
        return <ProjectHome 
                  projectName = {editMasterData.name} 
                  isPublished = {editMasterData.isPublished} 
                  emailPaywall = {editMasterData.services.emailWall.isActive}
                />;
      case 'Services':
        return <ProjectServices
                  editMasterData = {editMasterData}
                  setEditMasterData={setEditMasterData} 
                  handleSave={handleSave}
                />
      default:
        return <p>{currentTab}</p>;
    }
  };

  const selectTab = (tabName) => {
    setCurrentTab(tabName);
    console.log(tabName)
  };

  useEffect(() => {
    if( editMasterData ){
      //Mete a local storage
      //console.log("Master Data Edited")
      localStorage.setItem('masterData', JSON.stringify(editMasterData));
      console.log("Master data saved")
    }
  }, [ editMasterData ]);

  useEffect(() => {
    if( projectData ){
      //Mete a local storage
      localStorage.setItem('masterData', JSON.stringify(projectData));
      //Deep copy de valores
      setEditMasterData(JSON.parse(JSON.stringify(projectData)))
      localStorage.setItem('masterData', JSON.stringify(projectData));
      setIsLoaded(true)
    }
  }, [ projectData ]);



  return (
    <>
    <div className="block lg:flex lg:space-x-4 pb-4 mb-0">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          onChange={e => selectTab(e.target.value)}
          value={currentTab.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                onClick={(e) => {
                  e.preventDefault();
                  selectTab(tab.name);
                }}
                className={classNames(
                  currentTab === tab.name
                    ? 'border-kblue text-kblue'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                )}
                aria-current={currentTab === tab.name ? 'page' : undefined}
              >
                <tab.icon
                  className={classNames(
                    currentTab === tab.name ? 'text-kblue' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
    {isLoaded ? (
      <main className="py-0">
        <div className="py-0 px-4 sm:px-6 lg:px-8">
          {renderComponent()}
        </div>
      </main>
    ) : (<></>)}
    {!isLoaded ? (
      <Loader/>
      ):(
        <></>
      )}
    </>
  )
}
