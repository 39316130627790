import React from 'react';
import { Box, IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


const IconWhatsApp = ({
  number,
  message,
  margin = '10vh 0vw 0vh 86vw',
  iconBackgroundColor = '#59CE72',
  iconContainerBorderRadius = '100%',
  iconColor = 'white',
  iconSize = 'default'
  
}) => {
  function openChat() {
    console.log("Hello!");
    window.open(`https://wa.me/${number}?text=${message}`, '_blank');
  }

  return (
    <Box item 
      margin = {margin}
      position= 'fixed'
      zIndex= {1800}
      backgroundColor={iconBackgroundColor}
      borderRadius={iconContainerBorderRadius}

    >
      <IconButton 
        onClick={openChat}
      >
        <WhatsAppIcon style={{ color: iconColor, fontSize: iconSize }} />
      </IconButton>
    </Box>
  );
};

export default IconWhatsApp;