import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { PlusCircleIcon} from '@heroicons/react/20/solid'

export default function ProjectNewCard() {

  const navigate = useNavigate();

  return (
    <> 
      <li 
        className="cursor-pointer col-span-1 text-gray-400 hover:text-gray-300 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center border-2 border-dashed"
        onClick={() => navigate('/app/projects/new')}
      >
        <div className="flex flex-1 flex-col p-8">
        <PlusCircleIcon className=" group-hover:text-gray-100"/>
          <h3 className="mt-6 text-sm font-medium text-gray-900">New Project</h3>
        </div>
      </li>
    </>
  )
}