import React from 'react';

const EditorBorder = ({ text, children, external = true }) => {
  return (
    external ? (
    <div className="rounded-md my-2 px-3 pb-1.5 pt-2.5 border border-gray-200">
      <label className="block text-sm font-medium text-gray-900">
        {text}
      </label>
      <div className="ml-2 mt-2 mb-1">
        {children}
      </div>
    </div>
    ) : (
    <div className="rounded-md my-2 px-3 pb-1.5 pt-2.5 border border-gray-200">
      <label className="block text-xs font-sm text-gray-900">
        {text}
      </label>
      <div className="mb-1">
        {children}
      </div>
    </div>
    )
    
  );
};

export default EditorBorder;
