import React, { useEffect, useState, useRef } from 'react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { Collapse } from 'react-collapse';
import Loader from '../../utils/Loader';
import TextInput from '../../variableModifiers/TextInput';
import RangeSlider from '../../variableModifiers/RangeSlider';
import PositionEditor from '../../variableModifiers/PositionEditor';
import ImageUploader from '../../variableModifiers/ImagePreview';
import RadioButtonGroup from '../../variableModifiers/RadioButtonGroup';
import PaddingEditor from '../../variableModifiers/PaddingEditor';
import BorderSelector from '../../variableModifiers/BorderSelector';
import { mediaPositions } from '../../../../../utils/variables'
import ToggleSwitch from '../../variableModifiers/ToggleSwitch';
import { TrashIcon } from '@heroicons/react/16/solid';
import { updateProjectField } from '../../../../../api/project';
import { useParams } from 'react-router-dom';

export default function EditSectionBanner({
  editMasterData,
  setEditMasterData,
  sectionIndex,
  itemIndex,
}) {
  const [item, setItem] = useState(false);
  const [cardHeight, setCardHeight] = useState(undefined);
  const [cardMargin, setCardMargin] = useState(undefined);
  const [cardBorder, setCardBorder] = useState(undefined);
  const [mediaBorderRadius, setMediaBorderRadius] = useState(undefined);
  const [mediaBackgroundSize, setMediaBackgroundSize] = useState(undefined);
  const [image, setImage] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const contentRef = useRef(null);
  const { projectId } = useParams();

  useEffect(() => {
    if (editMasterData) {
      setItem(editMasterData.sections[sectionIndex].items[itemIndex]);
      setImage(editMasterData.sections[sectionIndex].items[itemIndex].img);
      setCardHeight(editMasterData.sections[sectionIndex].items[itemIndex].cardHeight);
      setCardMargin(editMasterData.sections[sectionIndex].items[itemIndex].cardMargin);
      setCardBorder(editMasterData.sections[sectionIndex].items[itemIndex].cardBorder);
      setMediaBorderRadius(editMasterData.sections[sectionIndex].items[itemIndex].cardBorderRadius);
      setMediaBackgroundSize(editMasterData.sections[sectionIndex].items[itemIndex].mediaBackgroundSize);
      setIsActive(editMasterData.sections[sectionIndex].items[itemIndex].isActive)
      setIsLoaded(true);
    }
  }, [editMasterData]);

  const handleInputChange = (event, value) => {
    const edit = editMasterData;
    switch (event.target.name) {
      case 'isActive':
        setIsActive(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].isActive = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'mediaBackgroundSize':
        setMediaBackgroundSize(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].mediaBackgroundSize = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'mediaBorderRadius':
        setMediaBorderRadius(value);
        edit.sections[sectionIndex].items[itemIndex].cardBorderRadius = value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'cardBorder':
        setCardBorder(value);
        edit.sections[sectionIndex].items[itemIndex].cardBorder = value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'img':
        setImage(value);
        edit.sections[sectionIndex].items[itemIndex].img = value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'cardHeight':
        setCardHeight(value);
        edit.sections[sectionIndex].items[itemIndex].cardHeight = value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'cardMargin':
        setCardMargin(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].cardMargin = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      default:
        console.log(event.target.name);
        return;
    }
  };

  const handleTrashClick = (e) => {
    e.stopPropagation();
    setShowConfirm(true);
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  const handleConfirm = async () => {
    const edit = editMasterData;
    edit.sections[sectionIndex].items.splice(itemIndex,1);
    await updateProjectField(projectId, 'sections', edit.sections);
    setEditMasterData(JSON.parse(JSON.stringify(edit)));
    setShowConfirm(false);
  };


  useEffect(() => {
    if (contentRef.current) {
      if (isActive && isOpen) {
        contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
      } else {
        contentRef.current.style.height = '0px';
      }
    }
  }, [isActive]);

  const contentHeight = isOpen ? `${contentRef.current.scrollHeight}px` : '0px';

  return (
    <>
      {editMasterData === undefined || !isLoaded ? (
        <Loader />
      ) : (
        <>
          <div className="flex items-center cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <ChevronDownIcon className="h-5 w-5 mr-2" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 mr-2" />
            )}
            <label className="block text-l font-medium text-gray-900">
              {editMasterData.sections[sectionIndex].items[itemIndex].viewName}
              <span className="text-gray-400 text-xs">
                {editMasterData.sections[sectionIndex].items[itemIndex].isActive ? "   Active" : " Inactive"}
              </span>
            </label>
            <TrashIcon
              className="h-4 w-4 ml-auto text-gray-500 hover:text-red-500"
              onClick={handleTrashClick}
            />
          </div>
          <div
            className="collapse-content"
            style={{ height: contentHeight, overflow: isOpen ? 'visible' : 'hidden' }}
            ref={contentRef}
          >
            <div>
              <ToggleSwitch
                refName="isActive"
                changeFunction={handleInputChange}
                text="Is Active"
                value={isActive}
              />
              { isActive ? (
                <>
                <ImageUploader
                  refName="img"
                  changeFunction={handleInputChange}
                  text="Banner Image"
                  value={image}
                />
                <RadioButtonGroup
                  refName="mediaBackgroundSize"
                  changeFunction={handleInputChange}
                  value={mediaBackgroundSize}
                  text="Image Position"
                  options={mediaPositions}
                />
                <RangeSlider
                  refName="mediaBorderRadius"
                  changeFunction={handleInputChange}
                  text="Image Border Radius"
                  value={mediaBorderRadius}
                  min={0}
                  max={100}
                  step={1}
                />
                <RangeSlider
                  refName="cardHeight"
                  changeFunction={handleInputChange}
                  text="Image Height"
                  value={cardHeight}
                  min={1}
                  max={50}
                  step={0.1}
                />
                <BorderSelector
                  initialBorder={cardBorder}
                  changeFunction={handleInputChange}
                  refName="cardBorder"
                  text="Card Border"
                />
                <PaddingEditor
                  value={cardMargin}
                  changeFunction={handleInputChange}
                  enableTop={true}
                  enableRight={true}
                  enableBottom={true}
                  enableLeft={true}
                  refName="cardMargin"
                  text="Spacing"
                  maxRight={30}
                  maxLeft={30}
                  minLeft={0}
                />
                </>
              ):(<></>)}
              
            </div>
          </div>
        </>
      )}
      {showConfirm && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p>Deletions are <u><b>permanent</b></u>, are you sure you want to delete this item?</p>
            <div className="mt-4 flex justify-end space-x-2 mt-10">
              <button
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
