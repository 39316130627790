import React, { useState, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

export default function PropsTableSortable({
  columns,
  data,
  tableTitle = "Default table",
  tableDesc = "Sortable table ",
  tableButton = "Download",
  defaultSortCol = 1
}) {
  const [items, setItems] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: columns[defaultSortCol].accessor,
    direction: 'ascending'
  });

  useEffect(() => {
    sortData();
  }, []); // Runs only once on component mount

  const sortData = (key = sortConfig.key, direction = sortConfig.direction) => {
    const sortedItems = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setItems(sortedItems);
    setSortConfig({ key, direction });
  };

  const onSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    sortData(key, direction);
  };

  return (
    <div className="px-4 sm:px-2 lg:px-0 w-full">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {columns.map((col) => (
                    <th
                      key={col.accessor}
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      <button type="button" className="group inline-flex" onClick={() => onSort(col.accessor)}>
                        {col.title}
                        {sortConfig.key === col.accessor && (
                          sortConfig.direction === 'ascending' ? 
                          <ChevronUpIcon className="h-5 w-5 ml-2 text-gray-400" aria-hidden="true" /> :
                          <ChevronDownIcon className="h-5 w-5 ml-2 text-gray-400" aria-hidden="true" />
                        )}
                      </button>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {items.map((item, index) => (
                  <tr key={index}>
                    {columns.map((col) => (
                      <td
                        key={col.accessor}
                        className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                      >
                        {item[col.accessor]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
