import React from 'react';
import EditorBorder from './EditorBorder';

const RadioButtonGroup = ({ 
  refName,
  changeFunction, 
  value,
  text,
  options
}) => {

  const handleChange = (e) => {
    const event = {
      target: {
          name: refName,
          value: e.target.value,
      },
    };
    changeFunction(event);
  };


  return (
    <EditorBorder text={text}>
      {options.map((option) => (
        <label key={option.value} className="block text-xs font-medium text-gray-900">
          <input
            type="radio"
            value={option.value}
            checked={value === option.value}
            onChange={handleChange}
            className="w-4 h-4 my-2 inline-block border border-gray-300 rounded-full text-blue-600 focus:ring-0 sm:text-sm sm:leading-6 appearance-none"
          />
          <span className="ml-2">{option.label}</span>
        </label>
      ))}
    </EditorBorder>
  );
};

export default RadioButtonGroup;
