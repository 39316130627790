import React, { useState, useEffect } from 'react'
import { useGetProjectData } from '../../../../api/project';
import Loader from '../../components/utils/Loader';
import { useNavigate } from 'react-router-dom';

export default function ProjectCard({projectId = ""}) {

  const [isLoaded, setIsLoaded] = useState(false)
  const [imgUrl, setImgUrl] = useState('')
  const { projectData } = useGetProjectData(projectId);
  const navigate = useNavigate();

  useEffect(() => {
    if(projectData){
      //console.log(headerStyles)
      setImgUrl(projectData.headerStyle.logoSrc)
      setIsLoaded(true)
    }
  }, [ projectData ]);

  return (
    <>
    {isLoaded ? (  
        <li 
          className="cursor-pointer col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
          onClick={() => navigate('/app/projects/'+projectId)}
        >
          <div className="flex flex-1 flex-col p-8">
            <img className="mx-auto h-auto w-auto flex-shrink-0 rounded-full" src={imgUrl} alt="" />
            <h3 className="mt-6 text-sm font-medium text-gray-900">{projectData.name}</h3>
            <dl className="mt-1 flex flex-grow flex-col justify-between">
              <dd className="mt-3">
              <span
                className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                  projectData.isPublished
                    ? "bg-green-50 text-green-700 ring-green-600/20"
                    : "bg-red-50 text-red-700 ring-red-600/20"
                }`}
              >
                {projectData.isPublished ? "Published" : "Private"}
              </span>
              </dd>
            </dl>
          </div>
        </li>
      ):(
        <Loader/>
      )}
    </>
  )
}
