import React from 'react';
import ItemCard from './ItemCard';
import ItemBanner from './ItemBanner';
import ItemLine from './ItemLine';
import ItemText from './ItemText';

const renderItem = (item, index) => {
    if (!item.isActive) {
      return null;
    }
    switch (item.type) {
        case 'side-image':
            return (
              <ItemCard
                key={index}
                imageUrl={item.img}
                title={item.name}
                description={item.smallSummary}
                price={item.price}
                cardHeight = {item.cardHeight}
                cardWidth = {item.cardWidth}
                cardMargin = {item.cardMargin}
                cardPadding = {item.cardPadding}
                cardShadow = {item.cardShadow}
                cardBorderRadius = {item.cardBorderRadius}
                cardBorder = {item.cardBorder}
                mediaHeight = {item.mediaHeight}
                mediaBorder = {item.mediaBorder}
                mediaBorderRadius = {item.mediaBorderRadius}
                mediaRatio =  {item.mediaRatio}
                mediaBackgroundSize = {item.mediaBackgroundSize}
                mediaMargin = {item.mediaMargin}
                imageToLeft = {item.imageToLeft}
                imageToRight = {item.imageToRight}

                contentPadding = {item.contentPadding}

                titleWhiteSpace = {item.titleWhiteSpace}
                titleWordWrap = {item.titleWordWrap}
                titleFontSize = {item.titleFontSize}
                titleFontFamily = {item.titleFontFamily}
                titleFontColor = {item.titleFontColor}
                titleTextAlign = {item.titleTextAlign}

                descWhiteSpace = {item.descWhiteSpace}
                descWordWrap = {item.descWordWrap}
                descFontSize = {item.descFontSize}
                descFontFamily = {item.descFontFamily}
                descFontColor = {item.descFontColor}
                descTextAlign = {item.descTextAlign}


                priceFontSize = {item.priceFontSize}
                priceFontFamily = {item.priceFontFamily}
                priceFontColor = {item.priceFontColor}
                pricePosition = {item.pricePosition}
                priceTop = {item.priceTop}
                priceRight = {item.priceRight}
                // Add other style props as needed
              />
            );
        // Add more cases as needed
        case 'banner':
          return (
            <ItemBanner
              key={index}
              imageUrl={item.img}
              cardHeight={item.cardHeight}
              cardMargin={item.cardMargin}
              cardPadding={item.cardPadding}
              cardShadow={item.cardShadow}
              cardBorderRadius={item.cardBorderRadius}
              cardBorder={item.cardBorder}
              mediaHeight={item.mediaHeight}
              mediaBorder={item.mediaBorder}
              mediaBorderRadius={item.mediaBorderRadius}
              mediaBackgroundSize={item.mediaBackgroundSize}
              cardOverflow={item.cardOverflow}
              cardWhiteSpace={item.cardWhiteSpace}
              cardAlign={item.cardAlign}
              cardDisplay={item.cardDisplay}
              cardJustify={item.cardJustify}
            />
          )
        case 'line':
          return (
            <ItemLine
            key={index}
            margin = {item.margin}
            borderBottom = {item.borderBottom}
            />
          )
        case 'text':
          return (
            <ItemText
              key={index}
              margin = {item.margin}
              text = {item.text}
              whiteScpace = {item.whiteScpace}
              wordWrap = {item.wordWrap}
              textAlign = {item.textAlign}
              fontSize = {item.fontSize}
              fontFamily = {item.fontFamily}
              color = {item.color}
            />
          )
        default:
            return null; // or return some default component
    }
};

export default renderItem;