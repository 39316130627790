import React from 'react';
import EmailData from './EmailData'; // Import the EmailData component

export default function ProjectHome({
  projectName,
  isPublished,
  emailPaywall
}) {
  return (
    <>
    <div className="bg-white shadow-lg rounded-lg p-6 w-full mx-auto mt-8">
      <h1 className="text-2xl font-bold text-gray-800 mb-4">{projectName}</h1>
      <div className="flex items-center justify-between mb-2">
        <span className="text-sm font-medium text-gray-600">Published:</span>
        <span
          className={`text-sm font-semibold ${
            isPublished ? 'text-green-600' : 'text-red-600'
          }`}
        >
          {isPublished ? 'Yes' : 'No'}
        </span>
      </div>
      <div className="flex items-center justify-between mb-4">
        <span className="text-sm font-medium text-gray-600">Email Wall:</span>
        <span
          className={`text-sm font-semibold ${
            emailPaywall ? 'text-green-600' : 'text-red-600'
          }`}
        >
          {emailPaywall ? 'Enabled' : 'Disabled'}
        </span>
      </div>
      
      {/* Include the EmailData component */}
      
    </div>
    <div className="bg-white shadow-md rounded-lg p-6 w-full mx-auto mt-8">
      <EmailData />
    </div>
    </>
  );
}