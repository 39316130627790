import React, { useState } from 'react'
import ProjectEditHeader from './ProjectEditHeader';
import ProjectPreview from '../utils/ProjectPreview'
import ProjectEditButtons from './ProjectEditButtons';
import ProjectEditContent from './ProjectEditContent';
import ProjectEditSections from './ProjectEditSections';

export default function ProjectEdit(
  {
    editMasterData,
    setEditMasterData,
    handleSave
  }
) {
  const [editingSection, setEditingSection] = useState("Header")

  const handleSelectChange = (event) => {
    setEditingSection(event.target.value)
  }

  const renderComponent = () => {
    switch (editingSection) {
      case "Header":
        return <ProjectEditHeader editMasterData={editMasterData} setEditMasterData={setEditMasterData}/>
      case 'Content':
        return <ProjectEditContent editMasterData={editMasterData} setEditMasterData={setEditMasterData}/>
      case 'Buttons':
        return <ProjectEditButtons editMasterData={editMasterData} setEditMasterData={setEditMasterData}/>;
      case "Sections Carrousel":
        return <ProjectEditSections editMasterData={editMasterData} setEditMasterData={setEditMasterData}/>; 
      default:
        return "Default";
    }
  };

  return (
    <>
      <div className="">
        <div className="lg:hidden mb-4">
          <button 
            className="bg-indigo-600 hover:bg-indigo-500 text-white text-sm py-2 px-4 rounded"
            onClick={handleSave}
            >
            Save Changes
          </button>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <label htmlFor="location" className="text-sm font-medium leading-6 text-gray-900">
              Editing:
            </label>
            <select
              id="location"
              name="location"
              className="mt-2 lg:mt-0 block w-full lg:w-auto rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={editingSection}
              onChange={handleSelectChange}
            >
              <option value="Header">Header</option>
              <option value="Sections Carrousel">Sections Carrousel</option>
              <option value="Content">Content</option>
              <option value="Buttons">Buttons</option>
            </select>
          </div>
          <button 
            className="hidden lg:inline-block bg-indigo-600 hover:bg-indigo-500 text-white text-sm px-4 rounded whitespace-nowrap h-10"
            onClick={handleSave}
            >
            Save Changes
          </button>
        </div>
        <div className="my-4 border-t border-gray-300"></div>
        <div className=''>
          <div className="flex flex-col lg:flex-row">
            <div className="w-full max-h-max lg:w-1/2 flex-grow">
              {renderComponent()}
            </div>
            <div className="w-full lg:w-1/2 flex-grow lg:block hidden py-2">
              <div className='sticky top-20 z-40 flex h-16 shrink-0 pl-20'>
                <ProjectPreview previewSrc={`${window.location.origin}/product/preview`}/>
              </div>
            </div>
            <div className="w-full lg:hidden flex text-center p-4">
              This preview is hidden unless you are on a computer.
            </div>
        </div>
      </div>
      </div>
    </>
  )
}
