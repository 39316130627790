import React, { useState, useEffect } from 'react'


import ProjectCard from './components/ProjectCard';
import Project from '../components/Project';
import { useParams } from 'react-router-dom';
import ProjectNewCard from './components/ProjectNewCard';
import ProjectNew from './components/ProjectNew';

export default function Projects({projectsArray = []}) {

  const { projectId } = useParams();

  const renderComponent = () => {
    switch (projectId) {
      case undefined:
        return (
          <ul className="grid grid-cols-3 gap-6 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4">
            {projectsArray.length > 0 && projectsArray.map((project, index) => (
              <ProjectCard key={index} projectId={project}/>
            ))}
            <ProjectNewCard/>
          </ul>
        )
      case 'new':
        return <ProjectNew/>
      default:
        return <Project projectId={projectId}/>;
    }
  };


  return (
    <>
      {renderComponent()}
    </>
  )
}
