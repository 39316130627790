import React, { useEffect, useState } from 'react'
import Loader from '../utils/Loader';
import TextInput from '../variableModifiers/TextInput';
import RangeSlider from '../variableModifiers/RangeSlider';
import RadioButtonGroup from '../variableModifiers/RadioButtonGroup';
import BorderSelector from '../variableModifiers/BorderSelector';
import ColorInput from '../variableModifiers/ColorInput';
import ShadowEditor from '../variableModifiers/ShadowEditor';
import ImageUploader from '../variableModifiers/ImagePreview';
import TextStyleEditor from '../variableModifiers/TextStyleEditor';
import WidthHeightEditor from '../variableModifiers/WidthHeightEditor';
import PaddingEditor from '../variableModifiers/PaddingEditor';

export default function ProjectEditSections(
  {
    editMasterData,
    setEditMasterData,
  }
) {

  const [backgroundColor, setBackgroundColor] = useState(undefined)
  const [bottomBorder, setBottomBorder] = useState(undefined);

  const [highlightBorder, setHighlightBorder] = useState(undefined)
  const [textSize, setTextSize] = useState(undefined)
  const [textColor, setTextColor] = useState(undefined)
  const [textFont, setTextFont] = useState(undefined)
  const [cardHeight, setCardHeight] = useState(undefined)
  const [cardRatio, setCardRatio] = useState(undefined)
  const [containerHeight, setContainerHeight] = useState(undefined)
  const [cardPadding, setCardPadding] = useState(undefined)
  const [cardBorderRadius, setCardBorderRadius] = useState(undefined)
  const [cardMarginRight, setCardMarginRight] = useState(undefined)

  const [selectedShadow, setSelectedShadow] = useState(undefined)
  const [standingShadow, setStandingShadow] = useState(undefined)

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (editMasterData) {
      setBackgroundColor(editMasterData.categoryStyle.backgroundColor)
      setBottomBorder(editMasterData.categoryStyle.cardContainerBorderBottom)
      setHighlightBorder(editMasterData.categoryStyle.highlightSettings)

      setTextSize(editMasterData.categoryStyle.textSize)
      setTextColor(editMasterData.categoryStyle.textColor)
      setTextFont(editMasterData.categoryStyle.fontFamily)

      setCardHeight(editMasterData.categoryStyle.cardHeight)
      setCardRatio(editMasterData.categoryStyle.cardRatio)
      setContainerHeight(editMasterData.categoryStyle.cardContainerHeight)
      setCardPadding(editMasterData.categoryStyle.cardContainerPadding)
      setCardBorderRadius(editMasterData.categoryStyle.cardBorderRadius)
      setCardMarginRight(editMasterData.categoryStyle.cardMarginRight)

      setSelectedShadow(editMasterData.categoryStyle.selectedShadow)
      setStandingShadow(editMasterData.categoryStyle.standingShadow)
      setIsLoaded(true);
    }
  }, [editMasterData]);

  const handleInputChange = (event, value) => {
    const edit = editMasterData
    switch (event.target.name) {
      case 'bottomBorder':
        setBottomBorder(value)
        edit.categoryStyle.cardContainerBorderBottom = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'selectedShadow':
        setSelectedShadow(event.target.value)
        edit.categoryStyle.selectedShadow = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'standingShadow':
        setStandingShadow(event.target.value)
        edit.categoryStyle.standingShadow = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'cardBorderRadius':
        setCardBorderRadius(value)
        edit.categoryStyle.cardBorderRadius = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return
      case 'cardPadding':
        setCardPadding(event.target.value)
        edit.categoryStyle.cardContainerPadding = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return
      case 'cardContainerHeight':
        setContainerHeight(value)
        edit.categoryStyle.cardContainerHeight = value
        let cardContainerHeight = parseFloat(value.match(/\d+(\.\d+)?/)[0]);
        let headerSize = parseFloat(edit.headerStyle.sectionHeight.match(/\d+(\.\d+)?/)[0]);
        edit.categoryStyle.contentMarginTop = headerSize + cardContainerHeight + "vh"
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'cardRatio':
        setCardRatio(value)
        edit.categoryStyle.cardRatio = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'cardHeight':
        setCardHeight(value)
        edit.categoryStyle.cardHeight = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'cardMarginRight':
        setCardMarginRight(value)
        edit.categoryStyle.cardMarginRight = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'textSize':
        setTextSize(value)
        edit.categoryStyle.textSize = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'textColor':
        setTextColor(value)
        edit.categoryStyle.textColor = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'textFont':
        setTextFont(value)
        edit.categoryStyle.fontFamily = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'backgroundColor':
        setBackgroundColor(event.target.value)
        edit.categoryStyle.backgroundColor = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return
      case 'highlightBorder':
        setHighlightBorder(value)
        edit.categoryStyle.highlightSettings = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      
      default:
        console.log(event.target.name)
        return
      }
  };

  return (
    <>
    {editMasterData === undefined || !isLoaded? (
      <Loader/>
      ):(
        <>
        <div className="rounded-md my-2 px-3 pb-1.5 pt-2.5 mb-12">
          <label className="block text-lg font-medium text-gray-900">
           Horizontal Container Settings
          </label>
          <div className="ml-1">
            <RangeSlider
              refName = {"cardContainerHeight"}
              changeFunction = {handleInputChange}
              text = {"Container Height"}
              value = {containerHeight}
              min = {1}
              max = {30}
              step = {0.1}
            />
            <ColorInput 
              refName = {"backgroundColor"}
              changeFunction = {handleInputChange}
              text = {"Background Color"}
              value = {backgroundColor}
            />
            <BorderSelector
              initialBorder={bottomBorder}
              changeFunction = {handleInputChange}
              refName={"bottomBorder"}
              text = {"Bottom Section Border"}
            />
            <PaddingEditor
              value={cardPadding}
              changeFunction={handleInputChange}
              enableTop={true}
              enableRight={false}
              enableBottom={false}
              enableLeft={true}
              refName="cardPadding"
              text={"Spacing"}
            />
          </div>
        </div>

        <div className="rounded-md my-2 px-3 pb-1.5 pt-2.5 mb-12">
          <label className="block text-ls font-medium text-gray-900">
           Card Settings
          </label>
          <div className="ml-1">
            <RangeSlider
              refName = {"cardBorderRadius"}
              changeFunction = {handleInputChange}
              text = {"Card Border Radius"}
              value = {cardBorderRadius}
              min = {0}
              max = {100}
              step = {1}
            />
            <WidthHeightEditor
              cardHeight={cardHeight}
              cardRatio={cardRatio}
              handleInputChange={handleInputChange}
              cardHeightMin = {1}
              cardHeightMax = {20}
              cardHeightStep = {0.1}
              cardRatioMin = {0.4}
              cardRatioMax = {5}
              cardRatioStep = {0.01}
              text={"Card Size"}
            />
            <TextStyleEditor
              initialColor={textColor}
              initialSize={textSize}
              initialFontFamily={textFont}
              changeFunction={handleInputChange}
              colorRefName={'textColor'}
              sizeRefName={'textSize'}
              fontFamilyRefName={'textFont'}
              text={"Text Style"}
            />
            <RangeSlider
              refName = {"cardMarginRight"}
              changeFunction = {handleInputChange}
              text = {"Between Card Spacing"}
              value = {cardMarginRight}
              min = {1}
              max = {15}
              step = {0.1}
            />
          </div>

        </div>

        <div className="rounded-md my-2 px-3 pb-1.5 pt-2.5 mb-12">
          <label className="block text-lg font-medium text-gray-900">
           Selection Settings
          </label>
          <div className="ml-1">
            <BorderSelector
              initialBorder={highlightBorder}
              changeFunction = {handleInputChange}
              refName={"highlightBorder"}
              text = {"Selected Card Highlight"}
            />
            <ShadowEditor 
              refName = {"selectedShadow"}
              changeFunction = {handleInputChange}
              text = {"Selected Card Shadow"}
              value = {selectedShadow}
              pickerPlacepent={"top"}
            />
            <ShadowEditor 
              refName = {"standingShadow"}
              changeFunction = {handleInputChange}
              text = {"Standing Card Shadow"}
              value = {standingShadow}
              pickerPlacepent={"top"}
            />
          </div>
        </div>
        </>
      )}
    </>
  )
}

