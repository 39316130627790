import React, { useEffect, useState } from 'react';
import Loader from '../utils/Loader';
import ProjectEditSection from './sections/ProjectEditSection';
import { PlusCircleIcon} from '@heroicons/react/20/solid'
import { useParams } from 'react-router-dom';
import { newSectionDefault } from '../../../../utils/variables';
import { updateProjectField } from '../../../../api/project';

export default function ProjectEditContent(
  {
    editMasterData,
    setEditMasterData,
  }
) {


  const [isLoaded, setIsLoaded] = useState(false);
  const { projectId } = useParams();

  useEffect(() => {
    if (editMasterData) {
      setIsLoaded(true);
    }
  }, [editMasterData]);

  const handleAddItem = async () => {
    //newSectionDefault
    const edit = editMasterData;
    edit.sections.push(newSectionDefault);
    await updateProjectField(projectId, 'sections', edit.sections);
    setEditMasterData(JSON.parse(JSON.stringify(edit)));
    //setShowConfirm(false);
  };

  return (
    <>
      {editMasterData === undefined || !isLoaded ? (
        <Loader />
      ) : (
        <>
          {editMasterData.sections?.length > 0 && editMasterData.sections.map((section, index) => (
          <div key={index}>
            <div className="rounded-md my-2 px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300">
              <ProjectEditSection
                editMasterData={editMasterData} 
                setEditMasterData={setEditMasterData}
                sectionIndex={index} />
            </div>
          </div>
        ))}
        <div className="cursor-pointer col-span-1 text-gray-400 hover:text-gray-300 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center border-2 border-dashed"
          onClick={handleAddItem}
        >
          <div className="flex flex-1 flex-col items-center p-8">
            <PlusCircleIcon className="w-6 h-6"/>
            <h3 className="mt-2 text-sm">New Section</h3>
          </div>
        </div>
        </>
      )}
    </>
  )
}
