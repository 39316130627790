import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

import Loader from '../../utils/Loader';
import TextInput from '../../variableModifiers/TextInput';
import EditSectionBanner from './EditSectionBanner';
import ImageUploader from '../../variableModifiers/ImagePreview';
import ToggleSwitch from '../../variableModifiers/ToggleSwitch';
import EditSectionLine from './EditSectionLine';
import EditSectionText from './EditSectionText';
import EditSectionItem from './EditSectionItem';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/16/solid';

import { updateProjectField } from '../../../../../api/project';
import { useParams } from 'react-router-dom';
import { newItemBanner,  newItemCard, newItemLine, newItemText} from '../../../../../utils/variables';

export default function ProjectEditSection({
  editMasterData,
  setEditMasterData,
  sectionIndex,
}) {
  const [section, setSection] = useState(undefined);
  const [hasImage, setHasImage] = useState(false);
  const [image, setImage] = useState(undefined);
  const [isActive, setIsActive] = useState(undefined);
  const [summary, setSummary] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showItemOptions, setShowItemOptions] = useState(false);
  const [newSelectedItem, setNewSelectedItem] = useState(null);

  const { projectId } = useParams();

  useEffect(() => {
    if (editMasterData) {
      setHasImage(editMasterData.sections[sectionIndex].hasImage);
      setImage(editMasterData.sections[sectionIndex].image);
      setIsActive(editMasterData.sections[sectionIndex].isActive);
      setSummary(editMasterData.sections[sectionIndex].summary);
      setSection(editMasterData.sections[sectionIndex]);
      setIsLoaded(true);
    }
  }, [editMasterData]);

  const renderItem = (item, index) => {
    switch (item.type) {
      case 'banner':
        return (
          <>
            <EditSectionBanner
              editMasterData={editMasterData}
              setEditMasterData={setEditMasterData}
              itemIndex={index}
              sectionIndex={sectionIndex}
            />
          </>
        );
      case 'line':
        return (
          <>
            <EditSectionLine
              editMasterData={editMasterData}
              setEditMasterData={setEditMasterData}
              itemIndex={index}
              sectionIndex={sectionIndex}
            />
          </>
        );
      case 'text':
        return (
          <>
            <EditSectionText
              editMasterData={editMasterData}
              setEditMasterData={setEditMasterData}
              itemIndex={index}
              sectionIndex={sectionIndex}
            />
          </>
        );
      case 'side-image':
        return (
          <>
            <EditSectionItem
              editMasterData={editMasterData}
              setEditMasterData={setEditMasterData}
              itemIndex={index}
              sectionIndex={sectionIndex}
            />
          </>
        );
      default:
        return null;
    }
  };

  const handleInputChange = (event, value) => {
    const edit = editMasterData;
    switch (event.target.name) {
      case 'summary':
        setSummary(event.target.value);
        edit.sections[sectionIndex].summary = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'hasImage':
        setHasImage(event.target.value);
        edit.sections[sectionIndex].hasImage = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'isActive':
        setIsActive(event.target.value);
        edit.sections[sectionIndex].isActive = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'img':
        setImage(value);
        edit.sections[sectionIndex].image = value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      default:
        console.log(event.target.name);
        return;
    }
  };

  const handleTrashClick = (e) => {
    e.stopPropagation();
    setShowConfirm(true);
  };

  const handleConfirm = async () => {
    const edit = editMasterData;
    edit.sections.splice(sectionIndex,1);
    await updateProjectField(projectId, 'sections', edit.sections);
    setEditMasterData(JSON.parse(JSON.stringify(edit)));
    setShowConfirm(false);
  };

  const handleAddItem = async () => {
    //newSectionDefault
    setShowItemOptions(true)
  };

  const handleConfirmAddItem = async () => {
    const edit = editMasterData;
    switch (newSelectedItem) {
      case "Banner":
        console.log("Banner")
        edit.sections[sectionIndex].items.push(newItemBanner);
        break;
      case "Line":
        console.log("Line")
        edit.sections[sectionIndex].items.push(newItemLine);
        break;
      case "Text":
        console.log("Text")
        edit.sections[sectionIndex].items.push(newItemText);
        break;
      case "Card":
        console.log("Card")
        edit.sections[sectionIndex].items.push(newItemCard);
        break;
      default:
        console.log("ERROR");
        break;
    }
    console.log(newSelectedItem)
    await updateProjectField(projectId, 'sections', edit.sections);
    setEditMasterData(JSON.parse(JSON.stringify(edit)));
    setNewSelectedItem(null)
    setShowItemOptions(false)
  }

  const handleCancel = () => {
    setShowConfirm(false);
  };

  return (
    <>
      {editMasterData === undefined || section === undefined || !isLoaded ? (
        <Loader />
      ) : (
        <>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <ChevronDownIcon className="h-5 w-5 mr-2" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 mr-2" />
            )}
            <label className="block text-l font-medium text-gray-900">
              {section.sectionName}
              <span className="text-gray-400 text-xs">
                {section.isActive ? "   Active" : " Inactive"}
              </span>
            </label>
            <TrashIcon
              className="h-4 w-4 ml-auto text-gray-500 hover:text-red-500"
              onClick={handleTrashClick}
            />
          </div>
          <Collapse isOpened={isOpen}>
            <div>
              <ToggleSwitch
                refName="isActive"
                changeFunction={handleInputChange}
                text="Active Section"
                value={isActive}
              />
              <TextInput
                refName="summary"
                changeFunction={handleInputChange}
                text="Section Text"
                value={summary}
              />
              <ToggleSwitch
                refName="hasImage"
                changeFunction={handleInputChange}
                text="Show Image"
                value={hasImage}
              />
              {hasImage ? (
                <ImageUploader
                  refName="img"
                  changeFunction={handleInputChange}
                  text="Section Image"
                  value={image}
                />
              ) : null}
              <label className="block text-s pt-4 font-medium text-gray-900">
                Section Items:
              </label>
              {editMasterData.sections.length > 0 &&
                editMasterData.sections[sectionIndex].items.map((item, index) => (
                  <div key={index}>
                    <div className="rounded-md z-10 my-2 mb-5 px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-200">
                      {renderItem(item, index)}
                    </div>
                  </div>
                ))}
                <div className="cursor-pointer col-span-1 text-gray-400 hover:text-gray-300 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center border-2 border-dashed"
                  onClick={handleAddItem}
                >
                  <div className="flex flex-1 flex-col items-center p-8">
                    <PlusCircleIcon className="w-6 h-6"/>
                    <h3 className="mt-2 text-sm">New Item</h3>
                  </div>
                </div>
            </div>
          </Collapse>
        </>
      )}
      {showItemOptions && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p>Please select an option for the new item:</p>
            <div className="grid grid-cols-2 gap-4 mt-4">
              {["Banner", "Card", "Line", "Text"].map((option, index) => (
                <div
                  key={index}
                  className={`cursor-pointer p-2 border ${newSelectedItem === option ? 'border-blue-500' : 'border-gray-300'} rounded`}
                  onClick={() => setNewSelectedItem(option)}
                >
                  <p className="text-center">{option}</p>
                </div>
              ))}
            </div>
            <div className="mt-4 flex justify-end space-x-2 mt-10">
              <button
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded"
                onClick={() => setShowItemOptions(false)}
              >
                Cancel
              </button>
              <button
                className={`bg-green-500 text-white px-4 py-2 rounded ${!newSelectedItem ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleConfirmAddItem}
                disabled={!newSelectedItem}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showConfirm && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p>Deletions are <u><b>permanent</b></u>, are you sure you want to delete this section?</p>
            <div className="mt-4 flex justify-end space-x-2 mt-10">
              <button
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
