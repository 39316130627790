import React, { useState, useEffect, useRef } from 'react';
import Chrome from '@uiw/react-color-chrome';
import { hsvaToHexa, hexToHsva } from '@uiw/color-convert';
import CustomDropdown from './CustomDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { fontOptions } from '../../../../utils/variables';
import EditorBorder from './EditorBorder';

const TextStyleEditor = ({ 
  initialColor = '#000000', 
  initialSize = '12vh',
  initialFontFamily = 'Arial',
  changeFunction,
  colorRefName,
  sizeRefName,
  fontFamilyRefName,
  text
}) => {
    const [textColor, setTextColor] = useState(initialColor);
    const [textSize, setTextSize] = useState(parseInt(initialSize.replace('vh', '')));
    const [fontFamily, setFontFamily] = useState(initialFontFamily);
    const [colorPickerVisible, setColorPickerVisible] = useState(false);
    const [hsva, setHsva] = useState(hexToHsva(initialColor));
    const colorPickerRef = useRef(null);

    useEffect(() => {
        setTextColor(initialColor);
        setTextSize(parseInt(initialSize.replace('vh', '')));
        setFontFamily(initialFontFamily);
        setHsva(hexToHsva(initialColor));
    }, [initialColor, initialSize, initialFontFamily]);

    const handleTextColorChange = (color) => {
        const newHex = hsvaToHexa(color.hsva);
        setHsva(color.hsva);
        setTextColor(newHex);
        const event = {
            target: {
                name: colorRefName,
                value: newHex,
            },
        };
        changeFunction(event, newHex);
    };

    const handleTextSizeChange = (e) => {
        const newSize = e.target.value + 'vh';
        setTextSize(e.target.value);
        const event = {
            target: {
                name: sizeRefName,
                value: newSize,
            },
        };
        changeFunction(event, newSize);
    };

    const handleFontFamilyChange = (value) => {
        setFontFamily(value);
        const event = {
            target: {
                name: fontFamilyRefName,
                value: value,
            },
        };
        changeFunction(event, value);
    };

    const handleClickOutside = (event) => {
        if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
            setColorPickerVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <EditorBorder text={text}>
            <div className="flex space-x-4">
                <div className="w-1/4 relative">
                    <label htmlFor="text-color" className="block text-xs font-medium text-gray-900">Color</label>
                    <div
                        id="text-color"
                        className="mt-1 block w-full px-3 py-1.5 text-base border border-gray-300 sm:text-sm rounded-md cursor-pointer"
                        onClick={() => setColorPickerVisible(!colorPickerVisible)}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                    >
                        <div className="w-6 h-6" style={{ backgroundColor: textColor, border: '1px solid gray', borderRadius: '4px' }}></div>
                    </div>
                    {colorPickerVisible && (
                        <div className="absolute z-10 mt-2" ref={colorPickerRef}>
                            <Chrome
                                color={hsva}
                                inputType={'hexa'}
                                onChange={handleTextColorChange}
                                showAlpha={false}
                                showHue={true}
                                showEditableInput={true}
                            />
                        </div>
                    )}
                </div>
                <div className='w-1/4'>
                    <label htmlFor="text-size" className="block text-xs font-medium text-gray-900">Size</label>
                    <input type="range" id="text-size" className="mt-1 block w-full"
                        min="0.0" max="5" step="0.1" value={textSize} onChange={handleTextSizeChange} />
                </div>
                <div className='w-1/2'>
                    <label htmlFor="font-family" className="block text-xs font-medium text-gray-900">Font</label>
                    <CustomDropdown 
                        options={fontOptions} 
                        selectedValue={fontFamily} 
                        onChange={handleFontFamilyChange} 
                    />
                </div>
            </div>
        </EditorBorder>
    );
};

export default TextStyleEditor;
