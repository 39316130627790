import React from 'react';
import { CardContent, Typography, Box } from '@mui/material';
import { StyledItemCard, StyledCardMedia } from '../styledItems/StyledCard';

// Item card component
const ItemCard = ({
  imageUrl,
  title,
  description,
  price,
  cardHeight = '15vh', 
  cardWidth = '86vw',
  cardMargin = '0.6vh', 
  cardPadding = '0vh', 
  cardShadow = '0 2px 4px rgba(0, 0, 0, 0.2)', 
  cardBorderRadius = '10px', 
  cardBorder = '1px solid #ddd', 
  mediaHeight = '15vh',
  mediaBorder = '1px solid green',
  mediaBorderRadius = '10px 0px 0px 10px',
  mediaRatio =  (15 / 9), 
  mediaBackgroundSize = 'contain',
  mediaMargin = '0',
  imageToLeft = true,
  imageToRight = false,

  contentPadding = '1vh 2vw 1vh 2vw',

  titleWhiteSpace = 'normal', 
  titleWordWrap = 'break-word', 
  titleFontSize = '2.5vh',
  titleFontFamily = 'Arial',
  titleFontColor = 'black',
  titleTextAlign = 'left',

  descWhiteSpace = 'normal', 
  descWordWrap = 'break-word', 
  descFontSize = '1.6vh',
  descFontFamily = 'Arial',
  descFontColor = 'gray',
  descTextAlign = 'left',

  priceFontSize = '1.6vh',
  priceFontFamily = 'Arial',
  priceFontColor = 'gray',
  pricePosition = 'absolute', // Keep absolute positioning
  priceTop = '12.5vh',
  priceRight = '2vw',
}) => {
  return (
    <StyledItemCard
      elevation={0}
      cardheight={cardHeight}
      cardmargin={cardMargin}
      cardpadding={cardPadding}
      cardshadow={cardShadow}
      cardborderradius={cardBorderRadius}
      cardborder={cardBorder}
    > 
      <Box position='relative'>
        <Box item display='flex'>
          {imageToLeft ? (
            <StyledCardMedia
              image={imageUrl}
              mediaheight={mediaHeight}
              mediaWidth={mediaHeight.match(/^\d+/)[0] * mediaRatio + "vw"}
              mediaBorder={mediaBorder}
              mediaBorderRadius={mediaBorderRadius}
              mediaBackgroundSize={mediaBackgroundSize}
              mediamargin={mediaMargin}
            />
          ) : (<></>)}
          <Box 
            display='flex' 
            flexDirection='column' 
            flexGrow={0} 
            width={cardWidth.match(/^\d+/)[0] - mediaHeight.match(/^\d+/)[0] + "vw"}
          > 
            <CardContent style={{
              padding: contentPadding
            }}>
              <Typography 
                style={{ 
                  whiteSpace: titleWhiteSpace, 
                  wordWrap: titleWordWrap, 
                  fontSize: titleFontSize,
                  fontFamily: titleFontFamily,
                  color: titleFontColor,
                  textAlign: titleTextAlign
                }}
              >
                {title}
              </Typography>
              <Typography 
                style={{ 
                  whiteSpace: descWhiteSpace, 
                  wordWrap: descWordWrap, 
                  fontSize: descFontSize,
                  fontFamily: descFontFamily,
                  color: descFontColor,
                  textAlign: descTextAlign
                }}
              >
                {description}
              </Typography>
            </CardContent>
          </Box>
          {imageToRight ? (
            <StyledCardMedia
              image={imageUrl}
              mediaheight={mediaHeight}
              mediaWidth={mediaHeight.match(/^\d+/)[0] * mediaRatio + "vw"}
              mediaBorder={mediaBorder}
              mediaBorderRadius={mediaBorderRadius}
              mediaBackgroundSize={mediaBackgroundSize}
              mediamargin={mediaMargin}
            />
          ) : (<></>)}
        </Box>
        {price !== undefined && (
          <Box
            style={{
              position: pricePosition,
              top: priceTop,
              right: priceRight,
            }}
          >
            <Typography 
              style={{ 
                fontSize: priceFontSize,
                fontFamily: priceFontFamily,
                color: priceFontColor,
              }}
            >
              {price}
            </Typography>
          </Box>
        )}
      </Box>
    </StyledItemCard>
  );
};

export default ItemCard;
