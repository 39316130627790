import React, { useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

// Customizing the font options
const Font = Quill.import('attributors/class/font');
Font.whitelist = [
  'arial', 
  'times-new-roman', 
  'courier', 
  'georgia', 
  'helvetica', 
  'verdana',
  'roboto',
  'Tiny5'
];
Quill.register(Font, true);

const TextEditor = ({ value, onChange }) => {
  const editorRef = useRef(null);
  const quillRef = useRef(null);

  const toolbarOptions = [
    [{ 'font': Font.whitelist }],
    [{ 'size': ['small', false, 'large', 'huge'] }], 
    ['bold', 'italic', 'underline', 'strike'], 
    [{ 'list': 'ordered' }, { 'list': 'bullet' }], 
    [{ 'indent': '-1' }, { 'indent': '+1' }], 
    [{ 'color': [] }, { 'background': [] }],
    [{ 'align': [] }],
    ['clean']
  ];

  useEffect(() => {
    if (!quillRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: toolbarOptions,
        },
      });

      quillRef.current.on('text-change', () => {
        const html = editorRef.current.querySelector('.ql-editor').innerHTML;
        onChange(html);
      });
    }

    if (quillRef.current && quillRef.current.root.innerHTML !== value) {
      quillRef.current.clipboard.dangerouslyPasteHTML(value);
    }
  }, [value, onChange]);

  return (
    <div>
      <div ref={editorRef}></div>
    </div>
  );
};

export default TextEditor;
