import { styled } from '@mui/material/styles';

export const ScrollableCardContainer = styled('div')(({ 
  theme, 
  backgroundColor,
  cardContainerPadding,
  cardContainerMarginTop,
  cardContainerHeight,
  cardContainerPosition,
  cardContainerBorderBottom
}) => ({
  //position: 'static',
  width: '100%',
  padding: cardContainerPadding,
  marginTop: cardContainerMarginTop,
  height: cardContainerHeight,
  position: cardContainerPosition,
  backgroundColor: backgroundColor || 'transparent',
  borderBottom: cardContainerBorderBottom,
  zIndex: 2, 
  alignContent: 'center',
}));



export const ItemsContainer = styled('div')(({ 
  contentMarginTop,
  contentHeight
}) => ({
  //position: 'static',
  width: '100%',
  padding: '2vh',
  marginTop: contentMarginTop,
  height: contentHeight,
  position: 'absolute',
  backgroundColor: "white" || 'transparent',
  zIndex: 1
}));

