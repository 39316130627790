import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { doc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { auth, firestore } from '../firebaseConfig';

// Helper function to find differences recursively
const getChangedFields = (original, updated) => {
  const changes = {};

  const compare = (orig, upd, path = '') => {
    for (const key in upd) {
      const fullPath = path ? `${path}.${key}` : key;
      if (typeof upd[key] === 'object' && upd[key] !== null && !Array.isArray(upd[key])) {
        compare(orig[key] || {}, upd[key], fullPath);
      } else if (Array.isArray(upd[key])) {
        if (JSON.stringify(upd[key]) !== JSON.stringify(orig[key])) {
          changes[fullPath] = upd[key];
        }
      } else if (upd[key] !== orig[key]) {
        changes[fullPath] = upd[key];
      }
    }
  };

  compare(original, updated);
  return changes;
};

// Fetcher function to get project data
const fetcher = async (docId) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }

  const docRef = doc(firestore, 'projects', docId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists() && docSnap.data().ownerId === user.uid) {
    return docSnap.data();
  } else {
    return "No access";
  }
};

// SWR hook to get project data
export function useGetProjectData(docId) {
  const { data, error, isValidating } = useSWR(docId ? docId : null, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
  });

  const refetchProjectData = () => {
    if (docId) {
      mutate(docId);
    }
  };

  const memoizedValue = useMemo(
    () => ({
      projectData: data,
      projectDataLoading: !data && !error,
      projectDataError: error,
      projectDataValidating: isValidating,
      projectDataEmpty: !data && !error,
      refetchProjectData,
    }),
    [data, error, isValidating]
  );

  return memoizedValue;
}

// Function to update a project document in Firestore with only the changed fields
export const updateProject = async (docId, updatedData) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }

  const docRef = doc(firestore, 'projects', docId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists() && docSnap.data().ownerId === user.uid) {
    const originalData = docSnap.data();
    const changes = getChangedFields(originalData, updatedData);

    if (Object.keys(changes).length === 0) {
      return 'No changes to update';
    }

    await updateDoc(docRef, changes);

    // Revalidate and update the SWR cache
    mutate(docId);
    return 'Project successfully updated!';
  } else {
    throw new Error('No such project or access denied!');
  }
};

// Function to update a specific field in a project document
export const updateProjectField = async (docId, field, value) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }

  const docRef = doc(firestore, 'projects', docId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists() && docSnap.data().ownerId === user.uid) {
    const updateData = { [field]: value };
    await updateDoc(docRef, updateData);

    // Revalidate and update the SWR cache
    mutate(docId);
    return 'Field successfully updated!';
  } else {
    throw new Error('No such project or access denied!');
  }
};

// Function to fetch a subcollection from a project document
export const fetchProjectSubcollection = async (docId, subcollectionName) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }

  const docRef = doc(firestore, 'projects', docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists() && docSnap.data().ownerId === user.uid) {
    const subcollectionRef = collection(firestore, `projects/${docId}/${subcollectionName}`);
    const subcollectionSnap = await getDocs(subcollectionRef);

    const subcollectionData = subcollectionSnap.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    return subcollectionData;
  } else {
    throw new Error('No such project or access denied!');
  }
};