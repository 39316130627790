import React, { useEffect, useState } from 'react'
import Loader from '../utils/Loader';
import TextInput from '../variableModifiers/TextInput';
import RangeSlider from '../variableModifiers/RangeSlider';
import RadioButtonGroup from '../variableModifiers/RadioButtonGroup';
import BorderSelector from '../variableModifiers/BorderSelector';
import ColorInput from '../variableModifiers/ColorInput';
import ShadowEditor from '../variableModifiers/ShadowEditor';
import ImageUploader from '../variableModifiers/ImagePreview';
import { logoPosHorizontalOptions } from '../../../../utils/variables'
import ToggleSwitch from '../variableModifiers/ToggleSwitch';


export default function ProjectEditHeader(
  {
    editMasterData,
    setEditMasterData,
  }
) {

  const [backgroundColor, setBackgroundColor] = useState(undefined);
  const [backgroundImage, setBackgroundImage] = useState(undefined);
  const [isImage, setIsImage] = useState(undefined);
  const [logoHeight, setLogoHeight] = useState(undefined);
  const [sectionHeight, setSectionHeight] = useState(undefined);
  const [logoMargin, setLogoMargin] = useState(undefined);
  const [bottomBorder, setBottomBorder] = useState(undefined);
  const [sectionBoxShadow, setSectionBoxShadow] = useState(undefined);
  const [logoSrc, setLogoSrc] = useState(undefined);
  const [hasLogo, setHasLogo] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if( editMasterData ){
      //lee datos
      console.log(editMasterData)
      setBackgroundColor(editMasterData.headerStyle.backgroundColor)
      setBackgroundImage(editMasterData.headerStyle.backgroundImage)
      const aux = editMasterData.headerStyle.backgroundImage !== "undefined"
      const aux2 = editMasterData.headerStyle.logoSrc !== ''
      setIsImage(aux)
      setHasLogo(aux2)
      setLogoHeight(editMasterData.headerStyle.maxLogoHeight)
      setSectionHeight(editMasterData.headerStyle.sectionHeight)
      setLogoMargin(editMasterData.headerStyle.boxMargin)
      setBottomBorder(editMasterData.headerStyle.sectionBottomBorder)
      setSectionBoxShadow(editMasterData.headerStyle.sectionBoxShadow)
      setLogoSrc(editMasterData.headerStyle.logoSrc)
      setIsLoaded(true)
      //let dataRead = localStorage.getItem('masterData')
      //console.log(JSON.parse(editMasterData).headerStyle)
      //setData(JSON.parse(editMasterData));
    }
  }, [editMasterData]);


  const handleInputChange = (event, value) => {
    const edit = editMasterData
    switch (event.target.name) {
      case 'logoSrc':
        setLogoSrc(value)
        edit.headerStyle.logoSrc = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return
      case 'backgroundColor':
        setBackgroundColor(event.target.value)
        edit.headerStyle.backgroundColor = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return
      case 'hasImage':
        setIsImage(event.target.value);
        if(event.target.value){
          edit.headerStyle.backgroundImage = "../../../../logoV.png";
        }else{
          edit.headerStyle.backgroundImage = 'undefined';
        }
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'hasLogo':
        setHasLogo(event.target.value);
        if(event.target.value){
          edit.headerStyle.logoSrc = "../../../../logoV.png";
        }else{
          edit.headerStyle.logoSrc = '';
        }
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'backgroundSrc':
        setBackgroundImage(value)
        edit.headerStyle.backgroundImage = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return
      case 'logoHeight':
        setLogoHeight(value)
        edit.headerStyle.maxLogoHeight = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'sectionHeight':
        setSectionHeight(value)
        edit.headerStyle.sectionHeight = value
        edit.categoryStyle.cardContainerMarginTop = value
        let headerSize = parseFloat(value.match(/\d+(\.\d+)?/)[0]);
        let cardContainerHeight = parseFloat(edit.categoryStyle.cardContainerHeight.match(/\d+(\.\d+)?/)[0]);
        edit.categoryStyle.contentMarginTop = headerSize + cardContainerHeight + "vh"
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'logoMargin':
        setLogoMargin(event.target.value)
        edit.headerStyle.boxMargin = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'bottomBorder':
        setBottomBorder(value)
        edit.headerStyle.sectionBottomBorder = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'sectionShadow':
        setSectionBoxShadow(event.target.value)
        edit.headerStyle.sectionBoxShadow = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      default:
        console.log(event.target.name)
        return
      }
  };

  return (
    
    <>
    {editMasterData === undefined || !isLoaded? (
      <Loader/>
      ):(
        <div className="rounded-md my-2 px-3 pb-1.5 pt-2.5">
          <ToggleSwitch
            refName="hasLogo"
            changeFunction={handleInputChange}
            text="Logo"
            value={hasLogo}
          />
          {hasLogo ? (
            <ImageUploader 
              refName = {"logoSrc"}
              changeFunction = {handleInputChange}
              text = {"Logo"}
              value = {logoSrc}
            />
          ):(
            <></>
          )}
          <ToggleSwitch
            refName="hasImage"
            changeFunction={handleInputChange}
            text="Background Image"
            value={isImage}
          />
          {isImage ? (
            <ImageUploader 
              refName = {"backgroundSrc"}
              changeFunction = {handleInputChange}
              text = {"Background Image"}
              value = {backgroundImage}
            />
          ):(
            <></>
          )}
          <ColorInput 
            refName = {"backgroundColor"}
            changeFunction = {handleInputChange}
            text = {"Background Color"}
            value = {backgroundColor}
          />
          
          <RangeSlider
            refName = {"logoHeight"}
            changeFunction = {handleInputChange}
            text = {"Logo Size"}
            value = {logoHeight}
            min = {2}
            max = {20}
            step = {0.1}
          />
          <RangeSlider
            refName = {"sectionHeight"}
            changeFunction = {handleInputChange}
            text = {"Header Height"}
            value = {sectionHeight}
            min = {2}
            max = {20}
            step = {0.1}
          />
          <RadioButtonGroup 
            refName = {"logoMargin"}
            changeFunction = {handleInputChange}
            value = {logoMargin}
            text = {"Logo Position"}
            options={logoPosHorizontalOptions}
          />
          <BorderSelector
            initialBorder={bottomBorder}
            changeFunction = {handleInputChange}
            refName={"bottomBorder"}
            text = {"Bottom Section Border"}
          />
          <ShadowEditor 
            refName = {"sectionShadow"}
            changeFunction = {handleInputChange}
            text = {"Section shadow"}
            value = {sectionBoxShadow}
            pickerPlacepent={"top"}
          />
        </div>
      )}
    </>
  )
}
