import React, { useEffect, useState, useRef } from 'react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import Loader from '../../utils/Loader';
import TextInput from '../../variableModifiers/TextInput';
import RangeSlider from '../../variableModifiers/RangeSlider';
import PositionEditor from '../../variableModifiers/PositionEditor';
import ImageUploader from '../../variableModifiers/ImagePreview';
import ToggleSwitch from '../../variableModifiers/ToggleSwitch';
import RadioButtonGroup from '../../variableModifiers/RadioButtonGroup';
import PaddingEditor from '../../variableModifiers/PaddingEditor';
import BorderSelector from '../../variableModifiers/BorderSelector';
import TextStyleEditor from '../../variableModifiers/TextStyleEditor';
import ShadowEditor from '../../variableModifiers/ShadowEditor';
import { imagePosition, mediaPositions, textAlign } from '../../../../../utils/variables'
import RadioButtonGroupBoolean from '../../variableModifiers/RadioButtonGroupBoolean';
import WidthHeightEditor from '../../variableModifiers/WidthHeightEditor';
import { TrashIcon } from '@heroicons/react/16/solid';
import { updateProjectField } from '../../../../../api/project';
import { useParams } from 'react-router-dom';

export default function EditSectionItem({
  editMasterData,
  setEditMasterData,
  sectionIndex,
  itemIndex,
}) {
  const [image, setImage] = useState(undefined);
  const [imageLeft, setImageLeft] = useState(undefined);
  const [hasImage, setHasImage ] = useState(undefined);
  const [mediaBackgroundSize, setMediaBackgroundSize] = useState(undefined);
  const [mediaBorderRadius, setMediaBorderRadius] = useState(undefined);
  const [mediaBorder, setMediaBorder] = useState(undefined);
  const [mediaHeight, setMediaHeight] = useState(undefined);
  const [mediaRatio, setMediaRatio] = useState(undefined);
  const [mediaMargin, setMediaMargin] = useState(undefined);

  const [title, setTitle] = useState(undefined);
  const [titleFontColor, setTitleFontColor] = useState(undefined);
  const [titleFontFam, setTitleFontFam] = useState(undefined);
  const [titleFontSize, setTitleFontSize] = useState(undefined);
  const [titleTextAlign, setTitleTextAlign] = useState(undefined);

  const [desc, setDesc] = useState(undefined);
  const [descFontColor, setDescFontColor] = useState(undefined);
  const [descFontFam, setDescFontFam] = useState(undefined);
  const [descFontSize, setDescFontSize] = useState(undefined);
  const [descTextAlign, setDescTextAlign] = useState(undefined);

  const [price, setPrice] = useState(undefined);
  const [priceFontColor, setPriceFontColor] = useState(undefined);
  const [priceFontFam, setPriceFontFam] = useState(undefined);
  const [priceFontSize, setPriceFontSize] = useState(undefined);
  const [priceRight, setPriceRight] = useState(undefined);
  const [priceTop, setPriceTop] = useState(undefined);

  
  const [cardHeight, setCardHaight] = useState(undefined)
  const [cardBorder, setCardBorder] = useState(undefined)
  const [cardBorderRadius, setCardBorderRadius] = useState(undefined)
  const [cardMargin, setCardMargin] = useState(undefined)
  const [cardPadding, setCardPadding] = useState(undefined)
  const [cardShadow, setCardShadow] = useState(undefined)

  const [contentPadding, setContentPadding] = useState(undefined)


  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const contentRef = useRef(null);
  const { projectId } = useParams();

  useEffect(() => {
    if (editMasterData) {
      setDesc(editMasterData.sections[sectionIndex].items[itemIndex].smallSummary)
      setDescFontColor(editMasterData.sections[sectionIndex].items[itemIndex].descFontColor)
      setDescFontFam(editMasterData.sections[sectionIndex].items[itemIndex].descFontFamily)
      setDescFontSize(editMasterData.sections[sectionIndex].items[itemIndex].descFontSize)
      setDescTextAlign(editMasterData.sections[sectionIndex].items[itemIndex].descTextAlign)

      setTitle(editMasterData.sections[sectionIndex].items[itemIndex].name)
      setTitleFontColor(editMasterData.sections[sectionIndex].items[itemIndex].titleFontColor)
      setTitleFontFam(editMasterData.sections[sectionIndex].items[itemIndex].titleFontFamily)
      setTitleFontSize(editMasterData.sections[sectionIndex].items[itemIndex].titleFontSize)
      setTitleTextAlign(editMasterData.sections[sectionIndex].items[itemIndex].titleTextAlign)

      setPrice(editMasterData.sections[sectionIndex].items[itemIndex].price)
      setPriceFontColor(editMasterData.sections[sectionIndex].items[itemIndex].priceFontColor)
      setPriceFontFam(editMasterData.sections[sectionIndex].items[itemIndex].priceFontFamily)
      setPriceFontSize(editMasterData.sections[sectionIndex].items[itemIndex].priceFontSize)
      setPriceRight(editMasterData.sections[sectionIndex].items[itemIndex].priceRight)
      setPriceTop(editMasterData.sections[sectionIndex].items[itemIndex].priceTop)

      setImage(editMasterData.sections[sectionIndex].items[itemIndex].img)
      setImageLeft(editMasterData.sections[sectionIndex].items[itemIndex].imageToLeft)
      setHasImage(editMasterData.sections[sectionIndex].items[itemIndex].imageToLeft || editMasterData.sections[sectionIndex].items[itemIndex].imageToRight)
      setMediaBackgroundSize(editMasterData.sections[sectionIndex].items[itemIndex].mediaBackgroundSize);
      setMediaBorderRadius(editMasterData.sections[sectionIndex].items[itemIndex].mediaBorderRadius);
      setMediaBorder(editMasterData.sections[sectionIndex].items[itemIndex].mediaBorder);
      setMediaHeight(editMasterData.sections[sectionIndex].items[itemIndex].mediaHeight);
      setMediaRatio(editMasterData.sections[sectionIndex].items[itemIndex].mediaRatio);
      setMediaMargin(editMasterData.sections[sectionIndex].items[itemIndex].mediaMargin);

      setCardHaight(editMasterData.sections[sectionIndex].items[itemIndex].cardHeight)
      setCardBorder(editMasterData.sections[sectionIndex].items[itemIndex].cardBorder)
      setCardBorderRadius(editMasterData.sections[sectionIndex].items[itemIndex].cardBorderRadius)
      setCardMargin(editMasterData.sections[sectionIndex].items[itemIndex].cardMargin)
      setCardPadding(editMasterData.sections[sectionIndex].items[itemIndex].cardPadding)
      setCardShadow(editMasterData.sections[sectionIndex].items[itemIndex].cardShadow)
      setContentPadding(editMasterData.sections[sectionIndex].items[itemIndex].contentPadding)

      setIsActive(editMasterData.sections[sectionIndex].items[itemIndex].isActive);
      setIsLoaded(true);
    }
  }, [editMasterData]);

  const handleInputChange = (event, value) => {
    const edit = editMasterData;
    switch (event.target.name) {
      case 'isActive':
        setIsActive(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].isActive = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'cardHeight':
        setCardHaight(value)
        edit.sections[sectionIndex].items[itemIndex].cardHeight = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'cardBorder':
        setCardBorder(value);
        edit.sections[sectionIndex].items[itemIndex].cardBorder = value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'cardBorderRadius':
        setCardBorderRadius(value);
        edit.sections[sectionIndex].items[itemIndex].cardBorderRadius = value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'cardMargin':
        setCardMargin(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].cardMargin = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'cardPadding':
        setCardPadding(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].cardPadding = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'cardShadow':
        setCardShadow(event.target.value)
        edit.sections[sectionIndex].items[itemIndex].cardShadow = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'mediaBorder':
        setMediaBorder(value);
        edit.sections[sectionIndex].items[itemIndex].mediaBorder = value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'mediaBackgroundSize':
        setMediaBackgroundSize(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].mediaBackgroundSize = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'mediaBorderRadius':
        setMediaBorderRadius(value);
        edit.sections[sectionIndex].items[itemIndex].mediaBorderRadius = value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'img':
        setImage(value);
        edit.sections[sectionIndex].items[itemIndex].img = value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'hasImage':
        setHasImage(event.target.value);
        setImageLeft(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].imageToLeft = event.target.value;
        edit.sections[sectionIndex].items[itemIndex].imageToRight = false;
        if(!event.target.value){
          edit.sections[sectionIndex].items[itemIndex].mediaHeight = '0vh'
        }else{
          edit.sections[sectionIndex].items[itemIndex].mediaHeight = '15vh'
        }
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'imageSide':
        setImageLeft(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].imageToLeft = event.target.value;
        edit.sections[sectionIndex].items[itemIndex].imageToRight = !event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'mediaRatio':
        setMediaRatio(value)
        edit.sections[sectionIndex].items[itemIndex].mediaRatio = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'mediaHeight':
        setMediaHeight(value)
        edit.sections[sectionIndex].items[itemIndex].mediaHeight = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'mediaMargin':
        setMediaMargin(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].mediaMargin = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'titleTextAlign':
        setTitleTextAlign(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].titleTextAlign = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'textSize':
        setTitleFontSize(value)
        edit.sections[sectionIndex].items[itemIndex].titleFontSize = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'textColor':
        setTitleFontColor(value)
        edit.sections[sectionIndex].items[itemIndex].titleFontColor = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'textFont':
        setTitleFontFam(value)
        edit.sections[sectionIndex].items[itemIndex].titleFontFamily = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'title':
        setTitle(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].name = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'descTextAlign':
        setDescTextAlign(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].descTextAlign = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'descSize':
        setDescFontSize(value)
        edit.sections[sectionIndex].items[itemIndex].descFontSize = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'descColor':
        setDescFontColor(value)
        edit.sections[sectionIndex].items[itemIndex].descFontColor = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'descFont':
        setDescFontFam(value)
        edit.sections[sectionIndex].items[itemIndex].descFontFamily = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'desc':
        setDesc(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].smallSummary = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'contentPadding':
        setContentPadding(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].contentPadding = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'priceSize':
        setPriceFontSize(value)
        edit.sections[sectionIndex].items[itemIndex].priceFontSize = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'priceColor':
        setPriceFontColor(value)
        edit.sections[sectionIndex].items[itemIndex].priceFontColor = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'priceFont':
        setPriceFontFam(value)
        edit.sections[sectionIndex].items[itemIndex].priceFontFamily = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'price':
        setPrice(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].price = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'priceTop':
        setPriceTop(value)
        edit.sections[sectionIndex].items[itemIndex].priceTop = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      case 'priceRight':
        setPriceRight(value)
        edit.sections[sectionIndex].items[itemIndex].priceRight = value
        setEditMasterData(JSON.parse(JSON.stringify(edit)))
        return 
      default:
        console.log(event)
        return;
    }
    
  };

  useEffect(() => {
    if (contentRef.current) {
      if (isActive && isOpen) {
        contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
      } else {
        contentRef.current.style.height = '0px';
      }
    }
  }, [isActive]);

  const handleTrashClick = (e) => {
    e.stopPropagation();
    setShowConfirm(true);
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  const handleConfirm = async () => {
    const edit = editMasterData;
    edit.sections[sectionIndex].items.splice(itemIndex,1);
    await updateProjectField(projectId, 'sections', edit.sections);
    setEditMasterData(JSON.parse(JSON.stringify(edit)));
    setShowConfirm(false);
  };

  const contentHeight = isOpen ? `${contentRef.current.scrollHeight}px` : '0px';

  return (
    <>
      {editMasterData === undefined || !isLoaded ? (
        <Loader />
      ) : (
        <>
          <div className="flex items-center cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <ChevronDownIcon className="h-5 w-5 mr-2" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 mr-2" />
            )}
            <label className="block text-l font-medium text-gray-900">
              {editMasterData.sections[sectionIndex].items[itemIndex].viewName}
              <span className="text-gray-400 text-xs">
                {editMasterData.sections[sectionIndex].items[itemIndex].isActive ? "   Active" : " Inactive"}
              </span>
            </label>
            <TrashIcon
              className="h-4 w-4 ml-auto text-gray-500 hover:text-red-500"
              onClick={handleTrashClick}
            />
          </div>
          <div
            className="collapse-content"
            style={{ height: contentHeight, overflow: isOpen ? 'visible' : 'hidden' }}
            ref={contentRef}
          >
            <div>
            <ToggleSwitch
                refName="isActive"
                changeFunction={handleInputChange}
                text="Is Active"
                value={isActive}
              />
              {isActive ? (
                <>
                <RangeSlider
                  refName="cardHeight"
                  changeFunction={handleInputChange}
                  text="Card Height"
                  value={cardHeight}
                  min={0}
                  max={50}
                  step={1}
                />
                <BorderSelector
                  initialBorder={cardBorder}
                  changeFunction={handleInputChange}
                  refName="cardBorder"
                  text="Card Border"
                  pickerPlacement='down'
                />
                <RangeSlider
                  refName="cardBorderRadius"
                  changeFunction={handleInputChange}
                  text="Card Border Radius"
                  value={cardBorderRadius}
                  min={0}
                  max={100}
                  step={1}
                />
                <PaddingEditor
                  value={cardMargin}
                  changeFunction={handleInputChange}
                  enableTop={true}
                  enableRight={true}
                  enableBottom={true}
                  enableLeft={true}
                  refName="cardMargin"
                  text="Card Margin"
                />
                <PaddingEditor
                  value={cardPadding}
                  changeFunction={handleInputChange}
                  enableTop={true}
                  enableRight={true}
                  enableBottom={false}
                  enableLeft={true}
                  refName="cardPadding"
                  text="Card Padding"
                />
                <ShadowEditor 
                  refName = {"cardShadow"}
                  changeFunction = {handleInputChange}
                  text = {"Card shadow"}
                  value = {cardShadow}
                  pickerPlacepent={"top"}
                />
                <ToggleSwitch
                  refName="hasImage"
                  changeFunction={handleInputChange}
                  text="Show Image"
                  value={hasImage}
                />
                {hasImage ? (
                  <>
                  <RadioButtonGroupBoolean
                    refName={'imageSide'}
                    changeFunction={handleInputChange}
                    value={imageLeft}
                    text="Image Position"
                    options={imagePosition}
                  />
                  <ImageUploader
                    refName="img"
                    changeFunction={handleInputChange}
                    text="Image"
                    value={image}
                  />
                  <RadioButtonGroup
                    refName="mediaBackgroundSize"
                    changeFunction={handleInputChange}
                    value={mediaBackgroundSize}
                    text="Image Position"
                    options={mediaPositions}
                  />
                  <RangeSlider
                    refName="mediaBorderRadius"
                    changeFunction={handleInputChange}
                    text="Image Border Radius"
                    value={mediaBorderRadius}
                    min={0}
                    max={100}
                    step={1}
                  />
                  <BorderSelector
                    initialBorder={mediaBorder}
                    changeFunction={handleInputChange}
                    refName="mediaBorder"
                    text="Image Border"
                    pickerPlacement='down'
                  />
                  <WidthHeightEditor
                    cardHeight={mediaHeight}
                    cardRatio={mediaRatio}
                    handleInputChange={handleInputChange}
                    cardHeightMin = {1}
                    cardHeightMax = {40}
                    cardHeightStep = {0.1}
                    cardRatioMin = {0.4}
                    cardRatioMax = {5}
                    cardRatioStep = {0.01}
                    text={"Image Size"}
                    heightRef = {"mediaHeight"}
                    ratioRef = {"mediaRatio"}
                    heightName = {"Image Height"}
                    ratioName = {"Image Width"}
                  />
                  <PaddingEditor
                    value={mediaMargin}
                    changeFunction={handleInputChange}
                    enableTop={true}
                    enableRight={true}
                    enableBottom={false}
                    enableLeft={true}
                    refName="mediaMargin"
                    text="Image Margin"
                  />
                  </>
                ) : null}
                <PaddingEditor
                  value={contentPadding}
                  changeFunction={handleInputChange}
                  enableTop={true}
                  enableRight={true}
                  enableBottom={true}
                  enableLeft={true}
                  refName="contentPadding"
                  text="Content Padding"
                />
                <TextInput
                  refName="title"
                  changeFunction={handleInputChange}
                  text="Item Title"
                  value={title}
                />
                <RadioButtonGroup
                  refName="titleTextAlign"
                  changeFunction={handleInputChange}
                  value={titleTextAlign}
                  text="Title Alignment"
                  options={textAlign}
                />
                <TextStyleEditor
                  initialColor={titleFontColor}
                  initialSize={titleFontSize}
                  initialFontFamily={titleFontFam}
                  changeFunction={handleInputChange}
                  colorRefName={'textColor'}
                  sizeRefName={'textSize'}
                  fontFamilyRefName={'textFont'}
                  text={"Title text Style"}
                />
                <TextInput
                  refName="desc"
                  changeFunction={handleInputChange}
                  text="Description"
                  value={desc}
                  isTextArea = {true}
                />
                <RadioButtonGroup
                  refName="descTextAlign"
                  changeFunction={handleInputChange}
                  value={descTextAlign}
                  text="Description Alignment"
                  options={textAlign}
                />
                <TextStyleEditor
                  initialColor={descFontColor}
                  initialSize={descFontSize}
                  initialFontFamily={descFontFam}
                  changeFunction={handleInputChange}
                  colorRefName={'descColor'}
                  sizeRefName={'descSize'}
                  fontFamilyRefName={'descFont'}
                  text={"Description text Style"}
                />
                <TextInput
                  refName="price"
                  changeFunction={handleInputChange}
                  text="Price"
                  value={price}
                />
                <TextStyleEditor
                  initialColor={priceFontColor}
                  initialSize={priceFontSize}
                  initialFontFamily={priceFontFam}
                  changeFunction={handleInputChange}
                  colorRefName={'priceColor'}
                  sizeRefName={'priceSize'}
                  fontFamilyRefName={'priceFont'}
                  text={"Price text Style"}
                />
                <WidthHeightEditor
                  cardHeight={priceTop}
                  cardRatio={priceRight}
                  handleInputChange={handleInputChange}
                  cardHeightMin = {0}
                  cardHeightMax = {47}
                  cardHeightStep = {0.1}
                  cardRatioMin = {1}
                  cardRatioMax = {83}
                  cardRatioStep = {0.01}
                  text={"Price Position"}
                  heightRef = {"priceTop"}
                  ratioRef = {"priceRight"}
                  heightName = {"Vertical"}
                  ratioName = {"Horizontal"}
                  />
                </>
              ):(<></>)}
              
              {/* Add other necessary input components similarly */}
            </div>
          </div>
        </>
      )}
      {showConfirm && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p>Deletions are <u><b>permanent</b></u>, are you sure you want to delete this item?</p>
            <div className="mt-4 flex justify-end space-x-2 mt-10">
              <button
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
