import React from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledAppBar = styled(AppBar)(({ theme, backgroundcolor, sectionheight, sectionposition, sectionbottomborder, sectionboxshadow, backgroundimage }) => ({
  backgroundColor: backgroundcolor,
  height: sectionheight,
  position: sectionposition,
  borderBottom: sectionbottomborder,
  boxShadow: sectionboxshadow,
  ...(backgroundimage !== "undefined" && {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${backgroundimage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      zIndex: -1, // Ensure the image is behind the content
    }
  })
}));


const Header = ({
  backgroundColor = "gray",
  logoSrc,
  backgroundImage,
  sectionHeight = '16vh',
  sectionPosition = 'fixed',
  sectionBottomBorder = '1px solid black',
  sectionBoxShadow = '0px 0px 8px rgba(0,0,0,0.5)',
  boxMargin = '2vh 0 3vh 0', 
  maxLogoHeight = '13vh'
}) => {
  return (
    <>

    <meta name="theme-color" content={backgroundColor}/>
    <meta name="msapplication-navbutton-color" content={backgroundColor}/>
    <meta name="apple-mobile-web-app-status-bar-style" content={backgroundColor}/>
    <StyledAppBar 
      /* position="sticky"  */
      elevation = {0}
      padding = {0}
      backgroundcolor={backgroundColor} 
      sectionheight={sectionHeight}
      sectionposition={sectionPosition}
      sectionbottomborder={sectionBottomBorder}
      sectionboxshadow={sectionBoxShadow}
      backgroundimage={backgroundImage}
      >
      <Toolbar disableGutters={true}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row', // Stack children vertically
            alignItems: 'center', // Center children horizontally
            justifyContent: 'center',
            width: '100%',
            padding: '0',
            margin: boxMargin,
          }}
        >
          {logoSrc && (
            <img
              src={logoSrc}
              alt="logo"
              style={{
                maxWidth: 'auto', // Limit the width of the image to 5% of its parent container
                maxHeight: maxLogoHeight
              }}
            />
          )}
        </Box>
      </Toolbar>
    </StyledAppBar>
    </>

  );
};

export default Header;