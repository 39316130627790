import React, { useState, useEffect } from 'react';
import PropsTableSortable from '../dataVisualization/PropsTableSortable';
import { fetchProjectSubcollection } from '../../../../api/project';
import { useParams } from 'react-router-dom';
import { ArrowPathIcon } from '@heroicons/react/20/solid'; // Import the circular arrow icon
import Loader from '../utils/Loader';

export default function EmailData() {
  // State to hold the fetched data
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { projectId } = useParams();
  const subcollectionName = 'mailWall';

  // Function to extract dynamic columns and format timestamp
  function extractColumnsAndData(apiData) {
    const staticColumns = [
      { title: "Name", accessor: "name", type: "string" },
      { title: "Email", accessor: "email", type: "string" },
      { title: "Date Submitted", accessor: "timestamp", type: "date" },
    ];

    // Extract dynamic keys
    const dynamicKeys = Object.keys(apiData).filter(
      (key) => !["name", "email", "id", "timestamp"].includes(key)
    );

    // Create dynamic columns
    const dynamicColumns = dynamicKeys.map((key) => ({
      title: key.charAt(0).toUpperCase() + key.slice(1),
      accessor: key,
      type: "string",
    }));

    // Combine static and dynamic columns
    const columns = [...staticColumns, ...dynamicColumns];

    // Format data
    const formattedData = {
      ...apiData,
      timestamp: new Date(apiData.timestamp.seconds * 1000).toLocaleDateString(
        "en-GB"
      ), // Format date as dd/mm/yyyy
    };

    return { columns, formattedData };
  }

  const fetchData = async () => {
    try {
      setLoading(true);
      const fetchedData = await fetchProjectSubcollection(projectId, subcollectionName);
      if (fetchedData.length > 0) {
        const { columns, formattedData } = extractColumnsAndData(fetchedData[0]);
        setColumns(columns);
        setData(fetchedData.map(data => extractColumnsAndData(data).formattedData));
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch data from the subcollection
    fetchData();
  }, [projectId, subcollectionName]);

  return (
    <div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold leading-6 text-gray-900">{"Form Entries"}</h1>
          <p className="mt-2 text-sm text-gray-700">
            {"All submissions on the email wall."}
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex gap-2">
          <button
            type="button"
            className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {"Download"}
          </button>
          <button
            type="button"
            onClick={fetchData}
            className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center"
          >
            <ArrowPathIcon className="h-5 w-5 mr-1" />
          </button>
        </div>
      </div>
      {loading ? (
        <Loader/>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <>
        <PropsTableSortable
          columns={columns}
          data={data}
          tableTitle="Form Entries"
          tableDesc="All submissions on the email wall."
          tableButton="Download"
          defaultSortCol={1}
        />
        </>
      )}
    </div>
  );
}
