import { Box } from '@mui/material';
import { StyledCard, StyledCardMedia } from '../styledItems/CategoryCardStyles'
import Typography from '@mui/material/Typography';

export const CategoryCard = ({
  sections,
  cardMarginRight,
  cardPaddingRight,
  handleCardSelection,
  selectedCard,
  highlightSettings,
  cardHeight,
  cardRatio,
  cardCursor,
  cardBorderRadius,
  cardTransition,
  selectedShadow,
  standingShadow,
  textSize,
  fontFamily,
  textColor,

}) => {

  return (
    <>
    {sections.map((section, index) => (
      section.isActive ? (
        <Box marginRight={cardMarginRight} paddingRight={cardPaddingRight} key={index}>
          {section.hasImage ? (
            <>
            <StyledCard 
              onClick={(e) => handleCardSelection(e, section, index)}
              isselected={index === selectedCard}
              highlightsettings = {highlightSettings}
              cardwidth= {cardHeight.match(/^\d+/)[0]*cardRatio+"vh"}
              cardheight= {cardHeight}
              cardcursor= {cardCursor}
              cardborderradius= {cardBorderRadius}
              cardtransition= {cardTransition}
              selectedshadow = {selectedShadow}
              standingshadow = {standingShadow}
            >
              <StyledCardMedia 
                image={section.image} 
                cardwidth= {cardHeight.match(/^\d+/)[0]*cardRatio+"vh"}
                cardheight= {cardHeight}
              />
            </StyledCard>
            <Typography
            style={{ 
              whiteSpace: 'normal', 
              wordWrap: 'break-word', 
              textAlign: 'center',
              fontSize: textSize,
              fontFamily: fontFamily,
              color: textColor
            }}
            >
              {section.summary}
            </Typography>
            </>
          ):(
            <StyledCard 
              onClick={(e) => handleCardSelection(e, section, index)}
              isselected={index === selectedCard}
              highlightsettings= {highlightSettings}
              cardwidth= {cardHeight.match(/^\d+/)[0]*cardRatio+"vh"}
              cardheight= {cardHeight}
              cardcursor= {cardCursor}
              cardborderradius= {cardBorderRadius}
              cardtransition= {cardTransition}
              selectedshadow = {selectedShadow}
              standingshadow = {standingShadow}
            >
              <Box 
                display='flex' 
                flexDirection='column' 
                flexGrow={0} 
                width={cardHeight.match(/^\d+/)[0]*cardRatio+"vh"} 
                height= {cardHeight}
              > 
                <Typography
                  style={{ 
                    whiteSpace: 'normal', 
                    wordWrap: 'break-word', 
                    fontSize: textSize,
                    fontFamily: fontFamily,
                    color: textColor,
                    height: '100%',
                    width: '100%',
                    display: 'flex', // Set display to flex
                    alignItems: 'center', // Align items vertically to the center
                    justifyContent: 'center'
                  }}
                >
                    {section.summary}
                </Typography>
              </Box>
            </StyledCard>
          )}
        </Box>

      ):(<></>)
    ))}
    </>
  )
}