import React from 'react';
import renderIcon from './IconButtons/renderIcon';

const Buttons = ({
  icons
}) => {
  //console.log(icons)
  return (
    icons.length > 0 ? (
      icons.map((icon, index) => (
        renderIcon(icon, index)
      ))
  ):(<></>)
  );
};

export default Buttons;