import React from 'react'
import EditorBorder from './EditorBorder';

export default function TextInput(
  {
    refName,
    changeFunction,
    text,
    value,
    isTextArea = false
  }
) {

  const handleChange = (e) => {
    const event = {
      target: {
        name: refName,
        value: e.target.value,
      },
    };
    changeFunction(event);
  };

  return (
    <>
      <EditorBorder text={text}>
      {!isTextArea ? (
        <input
          type="text"
          className="border w-full border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-500"
          placeholder="Enter text"
          value={value}
          onChange={handleChange}
        />
      ):(
        <textarea
          type="text"
          className="border w-full border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-500"
          placeholder="Enter text"
          value={value}
          onChange={handleChange}
        />
      )}
      </EditorBorder>
    </>
  )
}
