import { useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { auth, firestore } from '../firebaseConfig';
import { mutate } from 'swr';

const useUploadImage = () => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const resizeImage = (file, maxDimension, quality) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxDimension) {
            height = Math.round((height *= maxDimension / width));
            width = maxDimension;
          }
        } else {
          if (height > maxDimension) {
            width = Math.round((width *= maxDimension / height));
            height = maxDimension;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const resizedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            });
            resolve(resizedFile);
          },
          file.type,
          quality
        );
      };

      img.onerror = (err) => reject(err);
    });
  };

  const uploadImage = async (file) => {
    const user = auth.currentUser;
    if (!user) {
      setError('User not authenticated');
      return null;
    }

    setUploading(true);
    setError(null);

    try {
      const resizedFile = await resizeImage(file, 720, 0.7); // Resize to max 512x512 with 80% quality
      const storage = getStorage();
      const storageRef = ref(storage, `images/${user.uid}/${resizedFile.name}_${Date.now()}`);

      const snapshot = await uploadBytes(storageRef, resizedFile);
      const url = await getDownloadURL(snapshot.ref);

      await updateUserImages(url);

      setImageUrl(url);
      setUploading(false);
      return url;
    } catch (err) {
      setError(err.message);
      setUploading(false);
      return null;
    }
  };

  return { uploadImage, uploading, error, imageUrl };
};

export default useUploadImage;

export async function updateUserImages(url) {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }

  const userRef = doc(firestore, 'users', user.uid);

  try {
    await updateDoc(userRef, {
      images: arrayUnion(url)
    });
    mutate(user.uid); // Revalidate the SWR cache for user data
  } catch (err) {
    throw new Error('Failed to update user images');
  }
}

export async function deleteUserImage(url) {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }

  const userRef = doc(firestore, 'users', user.uid);
  const storage = getStorage();
  const imageRef = ref(storage, url);

  try {
    // Delete the image from Firebase Storage
    await deleteObject(imageRef);

    // Remove the URL from the user's images array in Firestore
    await updateDoc(userRef, {
      images: arrayRemove(url)
    });

    // Revalidate the SWR cache for user data
    mutate(user.uid);
  } catch (err) {
    throw new Error('Failed to delete user image');
  }
}
