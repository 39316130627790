import React from 'react';
import { StyledBannerCard, StyledCardMedia } from '../styledItems/StyledCard'


const ItemBanner = ({
  cardHeight = '30vh', 
  cardMargin = '0', 
  cardPadding = '0vh', 
  cardShadow = 'none', //'0 2px 4px rgba(0, 0, 0, 0.2)'
  cardBorderRadius = '10px', 
  cardBorder = '',
  imageUrl,

  mediaHeight = '100%',
  mediaBorder = '', //'1px solid green'
  mediaBorderRadius = '10px', //'10px 0px 0px 10px'
  mediaBackgroundSize = 'contain',
  cardOverflow = 'hidden',
  cardWhiteSpace = 'normal',
  cardAlign = 'center',
  cardDisplay = 'flex',
  cardJustify = 'center'

}) => {

  return (
    <StyledBannerCard
      cardheight={cardHeight}
      cardmargin={cardMargin}
      cardpadding={cardPadding}
      cardshadow={cardShadow}
      cardborderradius={cardBorderRadius}
      cardborder={cardBorder}
      cardoverflow={cardOverflow}
      cardwhitespace={cardWhiteSpace}
      cardalignitems={cardAlign}
      carddisplay={cardDisplay}
      cardjustifycontent={cardJustify}
> 
      <StyledCardMedia
        image={imageUrl}
        mediaheight={mediaHeight}
        mediaWidth={"100%"}
        mediaBorder={mediaBorder}
        mediaBorderRadius={mediaBorderRadius}
        mediaBackgroundSize={mediaBackgroundSize}
      />
    </StyledBannerCard>
  )
}

export default ItemBanner