import axios from 'axios';

// Create axios instance with a base URL
const axiosServices = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3010/',
  });
  
  // Updated fetcher function to handle different HTTP methods dynamically
  export const fetcher = async (args) => {
    const [url, config = {}] = Array.isArray(args) ? args : [args];
  
    // Default to 'GET' if no method is provided
    const method = config.method ? config.method.toLowerCase() : 'get';
  
    // Determine which axios method to use based on the method provided
    let response;
    if (method === 'post') {
      response = await axiosServices.post(url, config.data, { ...config });
    } else {
      response = await axiosServices.get(url, { ...config });
    }
  
    return response.data;
  };