import { CardContent } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

export const StyledCard = styled(Card)(({ 
  theme, 
  isselected, 
  highlightsettings,
  selectedshadow,
  standingshadow,
  cardwidth,
  cardheight,
  cardcursor,
  cardborderradius,
  cardtransition,
}) => ({
  border: `${isselected ? highlightsettings : 'none'}`,
  boxShadow: `${isselected ? selectedshadow : standingshadow}`,
  display: 'flex',
  flexDirection: 'row',
  userSelect: 'none',
  width: cardwidth,
  height: cardheight,
  cursor: cardcursor,
  borderRadius: cardborderradius,
  transition: cardtransition,
  WebkitTapHighlightColor: 'transparent'
}));

// Styling for the card media
export const StyledCardMedia = styled(CardMedia)((
  {
    cardwidth,
    cardheight,
  }
) => ({
  width: cardwidth,
  height: cardheight,
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  
}));
