import React, { useRef, useState, useEffect } from 'react';
import { Collapse } from 'react-collapse';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { ArrowUpIcon, ArrowDownIcon, TrashIcon } from '@heroicons/react/20/solid';
import Loader from '../utils/Loader';

export default function MailWallService({ editMasterData, setEditMasterData, handleSave }) {
  const dropdownRefs = useRef([]);
  const [isOpen, setIsOpen] = useState([]);
  const [mainText, setMainText] = useState(undefined);
  const [nameText, setNameText] = useState(undefined);
  const [emailText, setEmailText] = useState(undefined);
  const [btnText, setBtnText] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isActive, setIsActive] = useState(false); // New state variable

  useEffect(() => {
    if (editMasterData) {
      setMainText(editMasterData.services.emailWall.mainText);
      setNameText(editMasterData.services.emailWall.name);
      setEmailText(editMasterData.services.emailWall.email);
      setBtnText(editMasterData.services.emailWall.btnText);
      setIsActive(editMasterData.services.emailWall.isActive); // Set isActive state
      setIsLoaded(true);
    }
  }, [editMasterData]);

  const handleInputChange = (event) => {
    const edit = { ...editMasterData };
    switch (event.target.name) {
      case 'email':
        setEmailText(event.target.value);
        edit.services.emailWall.email = event.target.value;
        setEditMasterData(edit);
        return;
      case 'name':
        setNameText(event.target.value);
        edit.services.emailWall.name = event.target.value;
        setEditMasterData(edit);
        return;
      case 'mainText':
        setMainText(event.target.value);
        edit.services.emailWall.mainText = event.target.value;
        setEditMasterData(edit);
        return;
      case 'btnText':
        setBtnText(event.target.value);
        edit.services.emailWall.btnText = event.target.value;
        setEditMasterData(edit);
        return;
      default:
        console.log(event.target.name);
        return;
    }
  };

  const handleToggleActive = () => {
    const edit = { ...editMasterData };
    setIsActive(prevState => !prevState);
    edit.services.emailWall.isActive = !isActive;
    setEditMasterData(edit);
  };

  const addDropdownOption = (index) => {
    console.log(`Added new option at index ${index}`);
  };

  const handleArrowUpClick = (index) => {
    console.log(`Arrow Up clicked at index ${index}`);
  };

  const handleArrowDownClick = (index) => {
    console.log(`Arrow Down clicked at index ${index}`);
  };

  const handleTrashClick = (index) => {
    console.log(`Trash clicked at index ${index}`);
  };

  const handleTextChange = (event, index) => {
    const edit = { ...editMasterData };
    edit.services.emailWall.extras[index].name = event.target.value;
    setEditMasterData(edit);
    console.log(`Text changed at index ${index}:`, event.target.value);
  };

  const toggleCollapse = (index) => {
    setIsOpen(prevState => {
      const newOpenState = [...prevState];
      newOpenState[index] = !newOpenState[index];
      return newOpenState;
    });
  };

  const renderComponent = (extra, index) => {
    switch (extra.type) {
      case 'dropdown':
        return (
          <div key={index} className="space-y-2">
            <label className="text-sm font-medium text-gray-700">{extra.text + " - Dropdown list"}</label>
            <div className="flex items-center mt-2 ml-2">
              <button
                type="button"
                onClick={() => toggleCollapse(index)}
                className="flex items-center text-gray-700"
              >
                <span className="mr-2">Options</span>
                {isOpen[index] ? <ChevronDownIcon className="h-5 w-5" /> : <ChevronRightIcon className="h-5 w-5" />}
              </button>
            </div>
            <Collapse isOpened={isOpen[index]}>
              <div ref={el => (dropdownRefs.current[index] = el)} className="space-y-2 ml-3 mt-2">
                {extra.fields.map((field, optionIndex) => (
                  <div key={optionIndex} className="flex items-center space-x-2">
                    <input
                      type="text"
                      defaultValue={field}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    />
                    <button
                      type="button"
                      onClick={() => handleArrowUpClick(index)}
                      className="text-gray-700"
                    >
                      <ArrowUpIcon className="h-5 w-5" />
                    </button>
                    <button
                      type="button"
                      onClick={() => handleArrowDownClick(index)}
                      className="text-gray-700"
                    >
                      <ArrowDownIcon className="h-5 w-5" />
                    </button>
                    <button
                      type="button"
                      onClick={() => handleTrashClick(index)}
                      className="text-gray-700"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </div>
                ))}
                <div className="flex space-x-2 mt-2">
                  <button
                    type="button"
                    onClick={() => addDropdownOption(index)}
                    className="px-3 py-1 bg-blue-500 text-white rounded-md"
                  >
                    Add Option
                  </button>
                </div>
              </div>
            </Collapse>
          </div>
        );
      case 'text':
        return (
          <div key={index} className="space-y-2">
            <label className="text-sm font-medium text-gray-700">{extra.name + " - Text Field"}</label>
            <input
              type="text"
              value={extra.name || ''}
              onChange={(event) => handleTextChange(event, index)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
        );
      default:
        return <p key={index}>{extra.text}</p>;
    }
  };

  return (
    <div className="p-4 bg-white shadow-md rounded-md">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold">Mail Wall Service</h2>
        <div className="flex items-center space-x-4">
          <div className="flex items-center">
            <span className="mr-2 text-sm font-medium">{isActive ? 'Active' : 'Inactive'}</span>
            <div
              className={`w-3 h-3 rounded-full ${isActive ? 'bg-green-500' : 'bg-red-500'}`}
            ></div>
            <label className="ml-2 inline-flex relative items-center cursor-pointer">
              <input
                type="checkbox"
                checked={isActive}
                onChange={handleToggleActive}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-green-500 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-none after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:translate-x-full"></div>
            </label>
          </div>
          <button
            type="button"
            onClick={handleSave}
            className="px-4 py-2 bg-kblue text-white rounded-md shadow-sm hover:bg-fblue"
          >
            Save Changes
          </button>
        </div>
      </div>
      {!isLoaded ? (
        <Loader />
      ) : (
        <form className="space-y-4 mt-4">
          {/* Fixed Fields */}
          <h4 className="text-m font-semibold">Obligatory Fields</h4>
          <div className="ml-2">
            <div className="flex items-center space-x-4">
              <label className="text-sm font-medium text-gray-700">Main Text</label>
              <input
                type="text"
                name="mainText"
                value={mainText || ''}
                onChange={handleInputChange}
                className="mt-1 flex-1 border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>

            <div className="flex items-center space-x-4">
              <label className="text-sm font-medium text-gray-700">Email Text</label>
              <input
                type="email"
                name="email"
                value={emailText || ''}
                onChange={handleInputChange}
                className="mt-1 flex-1 border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div className="flex items-center space-x-4">
              <label className="text-sm font-medium text-gray-700">Name Text</label>
              <input
                type="text"
                name="name"
                value={nameText || ''}
                onChange={handleInputChange}
                className="mt-1 flex-1 border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            
            <div className="flex items-center space-x-4">
              <label className="text-sm font-medium text-gray-700">Button Text</label>
              <input
                type="text"
                name="btnText"
                value={btnText || ''}
                onChange={handleInputChange}
                className="mt-1 flex-1 border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
          </div>
          <h4 className="text-m mt-5 font-semibold">Extra Fields</h4>
          <div className="ml-2">
            {editMasterData.services.emailWall.extras.map((extra, index) => renderComponent(extra, index))}
          </div>
        </form>
      )}
    </div>
  );
}
