import { useMemo, useState, useEffect } from 'react';

export function useGetMasterData(catalogId) {

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      try {
        setIsLoading(true);
        // Retrieve the appStyle data from local storage
        const storedData = localStorage.getItem('masterData');
        if (storedData) {
          setData(JSON.parse(storedData));
        } else {
          setData(null); // No data found for the given catalogId
          setError(new Error('No style data found.'));
        }
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData(); // Fetch immediately on mount
    const interval = setInterval(fetchData, 80); 
    return () => clearInterval(interval); 

  }, []);

  //console.log(data)

  const memoizedValue = useMemo(
    () => ({
      masterData: data,
      masterDataLoading: isLoading,
      masterDataError: error
    }),
    [data, error, isLoading]
  );

  return memoizedValue;
  }