import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { auth, firestore } from '../firebaseConfig';


const fetcher = async (userId) => {
  const docRef = doc(firestore, 'users', userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    throw new Error('No such document!');
  }
};

export function useGetUserData() {
  const user = auth.currentUser;

  const { data, error, isValidating } = useSWR(user ? user.uid : null, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
  });

  const refetchUserData = () => {
    if (user) {
      mutate(user.uid);
    }
  };

  const memoizedValue = useMemo(
    () => ({
      userData: data,
      userDataLoading: !data && !error,
      userDataError: error,
      userDataValidating: isValidating,
      userDataEmpty: !data && !error,
      refetchUserData
    }),
    [data, error, isValidating]
  );

  return memoizedValue;
}

export async function updateUserImages(url) {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }

  const userRef = doc(firestore, 'users', user.uid);

  try {
    await updateDoc(userRef, {
      images: arrayUnion(url)
    });
    mutate(user.uid); // Revalidate the SWR cache for user data
  } catch (err) {
    throw new Error('Failed to update user images');
  }
}