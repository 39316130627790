import React from 'react';
import IconWhatsApp from './IconWhatsApp';

const renderIcon = (icon, index) => {
    if (!icon.isActive) {
      return null;
    }
    switch (icon.type) {
        case 'whatsapp':
            return (
              <IconWhatsApp
                number = {icon.number}
                message = {icon.message}
                margin = {icon.margin}
                iconBackgroundColor = {icon.iconBackgroundColor}
                iconContainerBorderRadius = {icon.iconContainerBorderRadius}
                iconColor = {icon.iconColor}
                iconSize = {icon.iconSize}
              />
            );
        // Add more cases as needed
        case '-':
          return (
            <></>
          )
        case '--':
          return (
            <></>
          )
        case '---':
          return (
            <></>
          )
        default:
            return null; // or return some default component
    }
};

export default renderIcon;