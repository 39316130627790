export const logoPosHorizontalOptions = [
  { label: "left", value: '2vh 41vw 2vh 0vw' },
  { label: "center", value: '2vh 0vw 2vh 0vw' },
  { label: "right", value: '2vh 0vw 2vh 50vw' },
]

export const imagePosition = [
  { label: 'left', value: true },
  { label: 'right', value: false }
]

export const textAlign = [
  { label: 'left', value: 'left' },
  { label: 'center', value: 'center' },
  { label: 'right', value: 'right' }
]

export const mediaPositions = [
  { label: 'cover', value: 'cover' },
  { label: 'contain', value: 'contain' },
];

export const fontOptions = [
  { value: 'Arial', label: 'Arial', premium: false },
  { value: 'Verdana', label: 'Verdana', premium: false },
  { value: 'Tahoma', label: 'Tahoma', premium: false },
  { value: 'Trebuchet MS', label: 'Trebuchet MS', premium: false },
  { value: 'Times New Roman', label: 'Times New Roman', premium: false },
  { value: 'Georgia', label: 'Georgia', premium: false },
  { value: 'Courier New', label: 'Courier New', premium: false },
  { value: 'Tiny5', label: 'Tiny5', premium: true },
  { value: 'Roboto', label: 'Roboto', premium: true },
];

export const newSectionDefault = {
  hasImage: "true",
  image: "https://firebasestorage.googleapis.com/v0/b/koalaty-dev.appspot.com/o/clientID%2FAsset%204adj.png?alt=media&token=e7cdeb3b-65ca-4a6e-9244-f85a4c7dbc6b",
  isActive: true,
  items: [],
  sectionName: "New Section",
  summary: "Hola",
  text: "some details... ",
}

export const newItemBanner = {
  type: 'banner',
  isActive: true,
  viewName: "Banner",
  cardHeight: '30vh', 
  cardMargin: '1.1vh 3.6vh 0vh 4.7vh', 
  cardPadding: '0vh', 
  cardShadow: 'none',
  cardBorderRadius: '10px', 
  cardBorder: '0px solid black',
  img: 'https://firebasestorage.googleapis.com/v0/b/koalaty-dev.appspot.com/o/clientID%2FAsset%204adj.png?alt=media&token=e7cdeb3b-65ca-4a6e-9244-f85a4c7dbc6b',
  mediaHeight: '100%',
  mediaBorder: '0px solid black', //'1px solid green'
  mediaBorderRadius: '10px 0px 0px 10px',
  mediaBackgroundSize: 'contain',
  cardOverflow: 'hidden',
  cardWhiteSpace: 'normal',
  cardAlign: 'center',
  cardDisplay: 'flex',
  cardJustify: 'center'
}

export const newItemWAButton = {
  type: 'whats-app',
  number: "5523090790",
  message: "Hello From Koalaty",
  margin: '10vh 0vw 0vh 86vw',
  iconBackgroundColor: '#59CE72',
  iconContainerBorderRadius: '100%',
  iconColor: 'white',
  iconSize: 'default'
}

export const newItemCard = {
  cardBorder: '0px solid #e98989ff',
  cardBorderRadius: '8px',
  cardHeight: '15vh',
  cardMargin: '1.6vh 0.3vh 2.7vh 0.5vh',
  cardPadding: '0vh 0vh 0vh 0vh',
  cardShadow: '0px 1.4px 7px #0000004a',
  cardWidth: '86vw',
  contentPadding: '0vh 1vh 0vh 1.6vh',
  descFontColor: 'gray',
  descFontFamily: 'Arial',
  descFontSize: '1.7vh',
  descTextAlign: 'left',
  descWhiteSpace: 'normal',
  descWordWrap: 'break-word',
  description: 'Alguna Descripcion',
  imageToLeft: true,
  imageToRight: false,
  img: 'https://firebasestorage.googleapis.com/v0/b/koalaty-dev.appspot.com/o/images%2FMakomb4yIIVwwWYZtB7Ih16Tihh2%2Fasiago-2.webp_1724524698231?alt=media&token=42dbf5f2-cfd9-4de3-8d3e-279b1623c4f4',
  isActive: true,
  mediaBackgroundSize: 'contain',
  mediaBorder: '0px solid green',
  mediaBorderRadius: '0px',
  mediaHeight: '15vh',
  mediaMargin: '0vh 0vh 0vh 0vh',
  mediaRatio: 1.6666666666666667,
  name: 'Queso Asiago Pressato DOP',
  price: '',
  priceFontColor: 'gray',
  priceFontFamily: 'Arial',
  priceFontSize: '1.6vh',
  pricePosition: 'absolute',
  priceRight: '2vw',
  priceTop: '12.5vh',
  smallSummary: 'Rueda de 12 kg',
  title: 'Card Nueva',
  titleFontColor: 'black',
  titleFontFamily: 'Roboto',
  titleFontSize: '2.3vh',
  titleTextAlign: 'left',
  titleWhiteSpace: 'normal',
  titleWordWrap: 'break-word',
  type: 'side-image',
  viewName: 'Card'
}


export const newItemLine = {
  type: 'line',
  isActive: true,
  viewName: "Line",
  margin: '0.5vh 6.3vh 2.5vh 5.5vh',
  borderBottom: '1px solid gray' 
}

export const newItemText = {
  type: 'text',
  isActive: true,
  viewName: "Text", 
  text: '',
  margin: '3vh 10vh 4.3vh 3.3vh',
}

