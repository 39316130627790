import React, { useState, useRef, useEffect } from 'react';
import Chrome from '@uiw/react-color-chrome';
import { hsvaToHexa, hexToHsva } from '@uiw/color-convert';
import EditorBorder from './EditorBorder';

export default function TextInput({
  refName,
  changeFunction,
  text,
  value,
}) {
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [hexValue, setHexValue] = useState(value);
  const [hsva, setHsva] = useState(hexToHsva(value));
  const colorPickerRef = useRef(null);

  const handleColorChange = (color) => {
    const newHex = hsvaToHexa(color.hsva);
    setHsva(color.hsva);
    setHexValue(newHex);
    const event = {
      target: {
        name: refName,
        value: newHex,
      },
    };
    changeFunction(event);
  };

  const handleClickOutside = (event) => {
    if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
      setColorPickerVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <EditorBorder text={text}>
        <div
          className="hover:cursor-pointer block w-full ml-2 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          onClick={() => setColorPickerVisible(!colorPickerVisible)}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', height: '2.5rem' }}
        >
          <div className="w-6 h-6" style={{ backgroundColor: hexValue, border: '1px solid gray', borderRadius: '4px' }}></div>
        </div>
        {colorPickerVisible && (
          <div className="absolute z-10 mt-2" ref={colorPickerRef}>
            <Chrome
              color={hsva}
              inputType={'hexa'}
              onChange={handleColorChange}
              showAlpha={false}
              showHue={true}
              showEditableInput={true}
            />
          </div>
        )}
      </EditorBorder>
    </>
  );
}
