import { useState } from 'react';
import { deleteUserImage } from '../../../../api/storage'


function ImageLibrary({ urls, onSelect, closeFunction, title }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState(urls);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageClick = (url) => {
    setSelectedImage(url);
  };

  const handleSelect = () => {
    onSelect(selectedImage);
  };

  const handleClose = () => {
    closeFunction();
  };

  const handleDeleteClick = (url) => {
    setImageToDelete(url);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    try {
      await deleteUserImage(imageToDelete);
      setImages(images.filter(image => image !== imageToDelete));
      setImageToDelete(null);
    } catch (error) {
      console.error('Error deleting image:', error.message);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setLoading(false);
    }
  };

  const handleCancelDelete = () => {
    setImageToDelete(null);
  };

  const handlePreviewClick = (url) => {
    setPreviewImage(url);
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
  };

  return (
    <div className="flex flex-col p-4 w-full h-full">
      <h2 className="text-2xl font-semibold mb-4">{title}</h2>
      <div className="flex-1 overflow-y-auto">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-6">
          {images.map((url, index) => (
            <div key={index} className="relative">
              <img 
                src={url} 
                alt={`gallery-${index}`} 
                className={`w-full h-32 object-cover cursor-pointer hover:opacity-75 border rounded-lg ${selectedImage === url ? 'border-4 border-blue-500' : 'border-gray-300'}`} 
                onClick={() => handleImageClick(url)}
              />
              <button 
                className="absolute top-1 right-1 bg-white text-gray-800 rounded-full p-1 hover:bg-gray-200 focus:outline-none"
                onClick={() => handleDeleteClick(url)}
              >
                &times;
              </button>
              <button 
                className="absolute bottom-1 left-1/2 transform -translate-x-1/2 bg-white border border-gray-200 text-gray-800 text-xs px-2 py-1 rounded hover:bg-gray-200 focus:outline-none"
                onClick={() => handlePreviewClick(url)}
              >
                Preview
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-end items-center mt-4">
        <button 
          className="text-gray-800 hover:text-gray-600 focus:outline-none border border-gray-300 px-4 py-2 rounded mr-2" 
          onClick={handleClose}
        >
          Cancel
        </button>
        <button 
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none" 
          onClick={handleSelect}
        >
          Select Image
        </button>
      </div>

      {imageToDelete && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-1/3">
            <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
            <p className="mb-4">Are you sure you want to delete this image?</p>
            <div className="flex justify-end space-x-4">
              <button 
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400 focus:outline-none"
                onClick={handleCancelDelete}
              >
                Cancel
              </button>
              <button 
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none"
                onClick={handleConfirmDelete}
              >
                {loading ? (
                  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                  </svg>
                ) : (
                  'Confirm'
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {previewImage && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg overflow-hidden">
            <img src={previewImage} alt="Preview" className="w-full max-h-screen object-contain" />
            <div className="flex justify-end p-4">
              <button 
                className="bg-white text-gray-800 px-4 py-2 border border-gray-300 rounded hover:bg-gray-200 focus:outline-none"
                onClick={handleClosePreview}
              >
                Close Preview
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageLibrary;
