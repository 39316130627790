import React, { useEffect, useRef } from 'react';
import './Menu.css';

export default function ProjectPreview({ previewSrc }) {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;

    const injectStyles = () => {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      if (iframeDocument) {
        const styleElement = iframeDocument.createElement('style');
        styleElement.innerHTML = `
          html, body {
            margin: 0;
            padding: 0;
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */
          }

          ::-webkit-scrollbar {
            width: 0;
            height: 0;
          }
        `;
        iframeDocument.head.appendChild(styleElement);
      }
    };

    if (iframe.contentWindow) {
      iframe.contentWindow.addEventListener('DOMContentLoaded', injectStyles);
    }

    iframe.addEventListener('load', injectStyles);
    return () => {
      if (iframe.contentWindow) {
        iframe.contentWindow.removeEventListener('DOMContentLoaded', injectStyles);
      }
      iframe.removeEventListener('load', injectStyles);
    };
  }, []);

  return (
    <div>
      <div className="menu-iframe-container items-center justify-center mx-auto" style={{ width: '324px', height: '576px', overflow: 'hidden', border: '2px solid gray', borderRadius: '20px' }}>
        <iframe ref={iframeRef} className="hide-scrollbar" src={previewSrc} style={{ width: '100%', height: '100%', overflow: 'auto' }}></iframe>
      </div>
    </div>
  );
}
