import React, { useState } from 'react';
import EditorBorder from './EditorBorder';

const RangeSlider = ({ refName, changeFunction, text, value, min, max, step }) => {
  const handleChange = (event) => {
    const newValue = event.target.value;
    changeFunction(refName, newValue);
  };

  return (
    <div className="rounded-md my-2 px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 flex-1">
      <label htmlFor={refName} className="block text-xs font-medium text-gray-900">
        {text}
      </label>
      <input
        type="range"
        id={refName}
        name={refName}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
      />
    </div>
  );
};

const PaddingEditor = ({
  value = '1.5vh 1.5vh 1.5vh 1.5vh',
  changeFunction,
  refName = 'padding',
  enableTop = true,
  enableRight = true,
  enableBottom = true,
  enableLeft = true,
  minTop = 0,
  maxTop = 10,
  minBottom = 0,
  maxBottom = 10,
  minLeft = 0,
  maxLeft = 10,
  minRight = 0,
  maxRight = 10,
  text
}) => {
  const parsePadding = (paddingString) => {
    const [top, right, bottom, left] = paddingString.split(' ');
    console.log("--" + top + "--" + right + "--" + bottom + "--" + left + "--")
    return {
      top: parseFloat(top?.slice(0, -2)),
      right: parseFloat(right?.slice(0, -2)),
      bottom: parseFloat(bottom?.slice(0, -2)),
      left: parseFloat(left?.slice(0, -2)),
    };
  };

  const { top, right, bottom, left } = parsePadding(value);
  const [paddingTop, setPaddingTop] = useState(top);
  const [paddingRight, setPaddingRight] = useState(right);
  const [paddingBottom, setPaddingBottom] = useState(bottom);
  const [paddingLeft, setPaddingLeft] = useState(left);

  const handlePaddingChange = (side, newValue) => {
    const newPaddingValues = {
      top: side === 'top' ? newValue : paddingTop,
      right: side === 'right' ? newValue : paddingRight,
      bottom: side === 'bottom' ? newValue : paddingBottom,
      left: side === 'left' ? newValue : paddingLeft,
    };

    setPaddingTop(newPaddingValues.top);
    setPaddingRight(newPaddingValues.right);
    setPaddingBottom(newPaddingValues.bottom);
    setPaddingLeft(newPaddingValues.left);

    const newPaddingValueString = `${newPaddingValues.top}vh ${newPaddingValues.right}vh ${newPaddingValues.bottom}vh ${newPaddingValues.left}vh`;
    const event = {
      target: {
        name: refName,
        value: newPaddingValueString,
      },
    };
    changeFunction(event);
  };

  const sliders = [
    enableTop && (
      <RangeSlider
        key="top"
        refName="top"
        changeFunction={handlePaddingChange}
        text="Top"
        value={paddingTop}
        min={minTop}
        max={maxTop}
        step={0.1}
      />
    ),
    enableRight && (
      <RangeSlider
        key="right"
        refName="right"
        changeFunction={handlePaddingChange}
        text="Right"
        value={paddingRight}
        min={minRight}
        max={maxRight}
        step={0.1}
      />
    ),
    enableBottom && (
      <RangeSlider
        key="bottom"
        refName="bottom"
        changeFunction={handlePaddingChange}
        text="Bottom"
        value={paddingBottom}
        min={minBottom}
        max={maxBottom}
        step={0.1}
      />
    ),
    enableLeft && (
      <RangeSlider
        key="left"
        refName="left"
        changeFunction={handlePaddingChange}
        text="Left"
        value={paddingLeft}
        min={minLeft}
        max={maxLeft}
        step={0.1}
      />
    ),
  ].filter(Boolean);

  return (
    <EditorBorder text={text}>
      <div className="flex flex-wrap -mx-2">
        {sliders.length === 1 && <div className="w-full px-2">{sliders[0]}</div>}
        {sliders.length === 2 && (
          <>
            <div className="w-1/2 px-2">{sliders[0]}</div>
            <div className="w-1/2 px-2">{sliders[1]}</div>
          </>
        )}
        {sliders.length === 3 && (
          <>
            <div className="w-1/3 px-2">{sliders[0]}</div>
            <div className="w-1/3 px-2">{sliders[1]}</div>
            <div className="w-1/3 px-2">{sliders[2]}</div>
          </>
        )}
        {sliders.length === 4 && (
          <>
            <div className="w-1/2 px-2">{sliders[0]}</div>
            <div className="w-1/2 px-2">{sliders[1]}</div>
            <div className="w-1/2 px-2 mt-4">{sliders[2]}</div>
            <div className="w-1/2 px-2 mt-4">{sliders[3]}</div>
          </>
        )}
      </div>
    </EditorBorder>
  );
};

export default PaddingEditor;
