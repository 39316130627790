import React from 'react'

export default function StatsBox({
  mainText = "Total Subscribers",
  mainNumber = "71,897"
}) {
  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate text-sm font-medium text-gray-500">{mainText}</dt>
      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{mainNumber}</dd>
    </div>
  )
}
