import React from 'react'

export default function Loader({hasText = true}) {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full relative">
      <img src="../../iPhone.png" alt="Spinning Image" className="animate-[slow-spin_1s_linear_infinite] h-32 w-32 object-contain"></img>
      {hasText ? (
        <p className="text-lg font-semibold mt-4">Loading...</p>
      ):(
        <></>
      )}
    </div>
  )
}
