import React, { useState, useEffect, useRef } from 'react';
import Chrome from '@uiw/react-color-chrome';
import { hsvaToHexa, hexToHsva } from '@uiw/color-convert';
import EditorBorder from './EditorBorder';

const BorderSelector = ({ 
  initialBorder = '1px solid blue', 
  changeFunction,
  refName,
  text,
  pickerPlacement = 'down'
}) => {
    const parseInitialBorder = (borderString) => {
        const [width, style, color] = borderString.split(' ');
        return {
            width: parseInt(width),
            style,
            color
        };
    };

    const { width: initialWidth, style: initialStyle, color: initialColor } = parseInitialBorder(initialBorder);

    const [borderColor, setBorderColor] = useState(initialColor);
    const [borderStyle, setBorderStyle] = useState(initialStyle);
    const [borderWidth, setBorderWidth] = useState(initialWidth);
    const [colorPickerVisible, setColorPickerVisible] = useState(false);
    const [hsva, setHsva] = useState(hexToHsva(initialColor));
    const colorPickerRef = useRef(null);

    useEffect(() => {
        const { width, style, color } = parseInitialBorder(initialBorder);
        setBorderColor(color);
        setBorderStyle(style);
        setBorderWidth(width);
        setHsva(hexToHsva(color));
    }, [initialBorder]);

    const handleBorderColorChange = (color) => {
        const newHex = hsvaToHexa(color.hsva);
        setHsva(color.hsva);
        setBorderColor(newHex);
        const event = {
            target: {
                name: refName,
                value: newHex,
            },
        };
        changeFunction(event, `${borderWidth}px ${borderStyle} ${newHex}`);
    };

    const handleClickOutside = (event) => {
        if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
            setColorPickerVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleBorderStyleChange = (e) => {
        setBorderStyle(e.target.value);
        e.target.name = refName;
        changeFunction(e, `${borderWidth}px ${e.target.value} ${borderColor}`);
    };

    const handleBorderWidthChange = (e) => {
        setBorderWidth(e.target.value);
        e.target.name = refName;
        changeFunction(e, `${e.target.value}px ${borderStyle} ${borderColor}`);
    };

    return (
        <EditorBorder text={text}>
        <div className="flex space-x-4">
            <div className="relative">
                <label htmlFor="border-color" className="block text-xs font-medium text-gray-900">Color</label>
                <div
                    id="border-color"
                    className="mt-1 block w-full px-3 py-1.5 text-base border border-gray-300 sm:text-sm rounded-md cursor-pointer"
                    onClick={() => setColorPickerVisible(!colorPickerVisible)}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                >
                    <div className="w-6 h-6" style={{ backgroundColor: borderColor, border: '1px solid gray', borderRadius: '4px' }}></div>
                </div>
                {colorPickerVisible && (
                    <div 
                        className={`absolute z-50 mt-2 ${pickerPlacement === 'down' ?  'top-full mt-2' : 'bottom-full mb-2' }`}
                        ref={colorPickerRef}
                        style={{ zIndex: 9999 }}
                    >
                        <Chrome
                            color={hsva}
                            inputType={'hexa'}
                            onChange={handleBorderColorChange}
                            showAlpha={false}
                            showHue={true}
                            showEditableInput={true}
                        />
                    </div>
                )}
            </div>
            <div>
                <label htmlFor="border-style" className="block text-xs font-medium text-gray-900">Style</label>
                <select id="border-style" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 sm:text-sm rounded-md"
                    value={borderStyle} onChange={handleBorderStyleChange}>
                    <option value="solid">Solid</option>
                    <option value="dashed">Dashed</option>
                    <option value="dotted">Dotted</option>
                </select>
            </div>
            <div>
                <label htmlFor="border-width" className="block text-xs font-medium text-gray-900">Width</label>
                <input type="number" id="border-width" className="mt-1 block w-full pl-3 pr-3 py-2 border border-gray-300 rounded-md text-sm shadow-sm focus:outline-none sm:text-sm" 
                    min="0" max="10" step="1" value={borderWidth} onChange={handleBorderWidthChange} />
            </div>
        </div>
      </EditorBorder>
    );
};

export default BorderSelector;
