import React, { useState, useEffect, useRef } from 'react';
import EditorBorder from './EditorBorder';
import ImageLibrary from '../utils/ImageLibrary';
import useUploadImage from '../../../../api/storage';
import Loader from '../utils/Loader';
import { useGetUserData } from '../../../../api/user';

export default function ImageUploader(
  {
    refName,
    changeFunction,
    text,
    value
  }
) {
  const [preview, setPreview] = useState(value);
  const [showPopup, setShowPopup] = useState(false);
  const inputRef = useRef();
  const { uploadImage, uploading, error, imageUrl } = useUploadImage();
  const { userData, refetchUserData } = useGetUserData();

  useEffect(() => {
    if (value) {
      setPreview(value);
    }
  }, [value]);

  const handleImageChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      const url = await uploadImage(file);
      if (url) {
        setPreview(url);
        changeFunction(e, url);
      }
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.target.name = refName;
    const file = e.dataTransfer.files[0];
    if (file) {
      const url = await uploadImage(file);
      if (url) {
        setPreview(url);
        changeFunction(e, url);
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDirectorySearch = () => {
    inputRef.current.click();
  };

  const handleBrowseLibraryClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleImageChoose = (url) => {
        setPreview(url);
        const e = {
          target: {
              name: refName
          },
        };
        changeFunction(e, url);
        setShowPopup(false);
  };

  return (
    <>
      <EditorBorder text={text}>
        <input
          type="file"
          name={refName}
          id={refName}
          className="hidden"
          ref={inputRef}
          onChange={handleImageChange}
          accept="image/*"
        />
        <div className="flex mt-2 space-x-4">
          <div className="relative w-20 h-20 rounded-md overflow-hidden">
            {uploading ? (
              <Loader hasText={false}/>
            ) : (
              preview ? (
                <img src={preview} alt="Preview" className="w-full h-full object-cover" />
              ) : (
                <p className="text-gray-500 text-center">No image selected</p>
              )
            )}
          </div>
          <div className="relative flex-1">
            <div
              htmlFor={refName}
              name={refName}
              id={refName}
              className="border-2 border-dashed border-gray-300 rounded-md p-4"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onClick={handleDirectorySearch}
            >
              <p className="text-gray-500 text-xs text-center">Drag and drop an image, or click to select one</p>
            </div>
            <button
              className="bg-blue-500 text-white rounded px-2 py-1 mt-2 text-xs"
              onClick={handleBrowseLibraryClick}
            >
              Browse Library
            </button>
          </div>
        </div>
      </EditorBorder>

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="relative bg-white w-4/5 h-4/5 rounded-lg p-4 flex flex-col justify-center items-center z-50">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={handleClosePopup}
            >
              &times;
            </button>
            <ImageLibrary 
              urls = {userData.images}
              onSelect={handleImageChoose}
              closeFunction={handleClosePopup}
              title="Select an Image from the Gallery"
            />
          </div>
        </div>
      )}
    </>
  );
}
