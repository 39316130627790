import React, { useEffect, useState, useRef } from 'react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import Loader from '../../utils/Loader';
import PaddingEditor from '../../variableModifiers/PaddingEditor';
import BorderSelector from '../../variableModifiers/BorderSelector';
import ToggleSwitch from '../../variableModifiers/ToggleSwitch';
import { TrashIcon } from '@heroicons/react/16/solid';
import { updateProjectField } from '../../../../../api/project';
import { useParams } from 'react-router-dom';

export default function EditSectionLine({
  editMasterData,
  setEditMasterData,
  sectionIndex,
  itemIndex,
}) {
  const [item, setItem] = useState(false);
  const [borderBottom, setBorderBottom] = useState(undefined);
  const [margin, setMargin] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const contentRef = useRef(null);
  const { projectId } = useParams();

  useEffect(() => {
    if (editMasterData) {
      setItem(editMasterData.sections[sectionIndex].items[itemIndex]);
      setBorderBottom(editMasterData.sections[sectionIndex].items[itemIndex].borderBottom);
      setMargin(editMasterData.sections[sectionIndex].items[itemIndex].margin);
      setIsActive(editMasterData.sections[sectionIndex].items[itemIndex].isActive);
      setIsLoaded(true);
    }
  }, [editMasterData]);

  const handleInputChange = (event, value) => {
    const edit = editMasterData;
    switch (event.target.name) {
      case 'isActive':
        setIsActive(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].isActive = event.target.value
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'borderBottom':
        setBorderBottom(value);
        edit.sections[sectionIndex].items[itemIndex].borderBottom = value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      case 'margin':
        setMargin(event.target.value);
        edit.sections[sectionIndex].items[itemIndex].margin = event.target.value;
        setEditMasterData(JSON.parse(JSON.stringify(edit)));
        return;
      default:
        console.log(event.target.name);
        return;
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      if (isActive && isOpen) {
        contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
      } else {
        contentRef.current.style.height = '0px';
      }
    }
  }, [isActive]);

  const handleTrashClick = (e) => {
    e.stopPropagation();
    setShowConfirm(true);
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  const handleConfirm = async () => {
    const edit = editMasterData;
    edit.sections[sectionIndex].items.splice(itemIndex,1);
    await updateProjectField(projectId, 'sections', edit.sections);
    setEditMasterData(JSON.parse(JSON.stringify(edit)));
    setShowConfirm(false);
  };

  const contentHeight = isOpen ? `${contentRef.current.scrollHeight}px` : '0px';

  return (
    <>
      {editMasterData === undefined || !isLoaded ? (
        <Loader />
      ) : (
        <>
          <div className="flex items-center cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <ChevronDownIcon className="h-5 w-5 mr-2" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 mr-2" />
            )}
            <label className="block text-l font-medium text-gray-900">
              {editMasterData.sections[sectionIndex].items[itemIndex].viewName}
              <span className="text-gray-400 text-xs">
                {editMasterData.sections[sectionIndex].items[itemIndex].isActive ? "   Active" : " Inactive"}
              </span>
            </label>
            <TrashIcon
              className="h-4 w-4 ml-auto text-gray-500 hover:text-red-500"
              onClick={handleTrashClick}
            />
          </div>
          <div
            className="collapse-content"
            style={{ height: contentHeight, overflow: isOpen ? 'visible' : 'hidden' }}
            ref={contentRef}
          >
            <div>
              <ToggleSwitch
                refName="isActive"
                changeFunction={handleInputChange}
                text="Is Active"
                value={isActive}
              />
              { isActive ? (
              <>
                <BorderSelector
                  initialBorder={borderBottom}
                  changeFunction={handleInputChange}
                  refName="borderBottom"
                  text="Border Bottom"
                />
                <PaddingEditor
                  value={margin}
                  changeFunction={handleInputChange}
                  enableTop={true}
                  enableRight={true}
                  enableBottom={true}
                  enableLeft={true}
                  refName="margin"
                  text="Margin"
                />
              </>
            ):(<></>)}
              
            </div>
          </div>
        </>
      )}
      {showConfirm && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p>Deletions are <u><b>permanent</b></u>, are you sure you want to delete this item?</p>
            <div className="mt-4 flex justify-end space-x-2 mt-10">
              <button
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
