import React, { useState, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'

const projectsData = [
  { name: 'Queso Chedrahui1', datePublished: '04/10/2024', views24: 231, viewsAll: 5340, status: "Active" },
  { name: 'Queso Chedrahui2', datePublished: '05/10/2024', views24: 232, viewsAll: 5341, status: "Active" },
  { name: 'Queso Chedrahui3', datePublished: '06/10/2024', views24: 233, viewsAll: 5342, status: "Active" },
  { name: 'Queso Chedrahui4', datePublished: '07/10/2024', views24: 234, viewsAll: 5343, status: "Inactive" },
  { name: 'Queso Chedrahui5', datePublished: '08/10/2024', views24: 235, viewsAll: 5344, status: "Inactive" },
];

const columns = [
  { title: "Name", accessor: "name", type: "string" },
  { title: "Date Published", accessor: "datePublished", type: "date" },
  { title: "Views (24h)", accessor: "views24", type: "number" },
  { title: "Total Views", accessor: "viewsAll", type: "number" },
  { title: "Status", accessor: "status", type: "string" },
];

export default function TableSortable({
  setActiveNavItem,
  tableTitle = "Projects",
  tableDesc = "A list of all active and Inactive projects associated to your account",
  tableButton = "New Project",
  defaultSortCol = 1,

}) {

  const [projects, setProjects] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: columns[defaultSortCol].accessor, direction: 'ascending' });

  useEffect(() => {
    sortData();
  }, []); // Runs only once on component mount

  const sortData = (key = sortConfig.key, direction = sortConfig.direction) => {
    const sortedProjects = [...projectsData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setProjects(sortedProjects);
    setSortConfig({ key, direction });
  };

  const onSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    sortData(key, direction);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">{tableTitle}</h1>
          <p className="mt-2 text-sm text-gray-700">
            {tableDesc}
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-kblue px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-fblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {tableButton}
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                {columns.map((col) => (
                    <th key={col.accessor} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      <button type="button" className="group inline-flex" onClick={() => onSort(col.accessor)}>
                        {col.title}
                        {sortConfig.key === col.accessor && (
                          sortConfig.direction === 'ascending' ? 
                          <ChevronUpIcon className="h-5 w-5 ml-2 text-gray-400" aria-hidden="true" /> :
                          <ChevronDownIcon className="h-5 w-5 ml-2 text-gray-400" aria-hidden="true" />
                        )}
                      </button>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {projects.map((project, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {project.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{project.datePublished}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{project.views24}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{project.viewsAll}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{project.status}</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0">
                      <a href="#" className="text-kblue hover:text-fblue" onClick={() => setActiveNavItem("Projects")}>
                        Edit
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
