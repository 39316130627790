import React, { useState, useRef, useEffect } from 'react';
import Chrome from '@uiw/react-color-chrome';
import { hsvaToHexa, hexToHsva } from '@uiw/color-convert';
import { GithubPlacement } from '@uiw/react-color-github';
import EditorBorder from './EditorBorder';

const ColorPickerInput = ({ 
  color, 
  onChange,
  pickerPlacement = 'top',
  disabled
}) => {
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [hexValue, setHexValue] = useState(color);
  const [hsva, setHsva] = useState(hexToHsva(color));
  const colorPickerRef = useRef(null);
  const inputRef = useRef(null);

  const handleColorChange = (color) => {
    const newHex = hsvaToHexa(color.hsva);
    setHsva(color.hsva);
    setHexValue(newHex);
    onChange(newHex);
  };

  const handleClickOutside = (event) => {
    if (
      colorPickerRef.current &&
      !colorPickerRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setColorPickerVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <div
        ref={inputRef}
        className="block w-full border-0 p-0 ml-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 cursor-pointer"
        onClick={() => !disabled && setColorPickerVisible(!colorPickerVisible)}
        style={{ display: 'flex', alignItems: 'center', height: '2.5rem', pointerEvents: disabled ? 'none' : 'auto' }}
      >
        <div className="w-6 h-6 mr-2" style={{ backgroundColor: hexValue, border: '1px solid gray', borderRadius: '4px' }}></div>
      </div>
      {colorPickerVisible && (
        <div
          className={`absolute z-10 mt-2 ${pickerPlacement === 'top' ? 'bottom-full mb-2' : 'top-full mt-2'}`}
          ref={colorPickerRef}
        >
          <Chrome color={hsva} onChange={handleColorChange} placement={GithubPlacement.BottomLeft} />
        </div>
      )}
    </div>
  );
};

const ShadowEditor = ({ 
  value = '0px 0px 8px #FFFFFF', 
  pickerPlacement = 'top',
  refName,
  changeFunction, 
  text
}) => {
  const parseShadow = (shadowString) => {
    if (shadowString === 'none') {
      return {
        offsetX: 0,
        offsetY: 0,
        blurRadius: 0,
        color: '#FFFFFF',
      };
    }
    const [offsetX, offsetY, blurRadius, color] = shadowString.split(' ');
    const hsvaColor = hexToHsva(color);
    return {
      offsetX: parseFloat(offsetX),
      offsetY: parseFloat(offsetY),
      blurRadius: parseFloat(blurRadius),
      color: hsvaToHexa(hsvaColor),
    };
  };

  const { offsetX: initialOffsetX, offsetY: initialOffsetY, blurRadius: initialBlurRadius, color: initialColor } = parseShadow(value);

  const [offsetX, setOffsetX] = useState(initialOffsetX);
  const [offsetY, setOffsetY] = useState(initialOffsetY);
  const [blurRadius, setBlurRadius] = useState(initialBlurRadius);
  const [color, setColor] = useState(initialColor);
  const [isEditable, setIsEditable] = useState(true);

  const handleOffsetXChange = (e) => {
    setOffsetX(e.target.value);
    const event = {
      target: {
        name: refName,
        value: `${e.target.value}px ${offsetY}px ${blurRadius}px ${color}`,
      },
    };
    changeFunction(event);
  };

  const handleOffsetYChange = (e) => {
    setOffsetY(e.target.value);
    const event = {
      target: {
        name: refName,
        value: `${offsetX}px ${e.target.value}px ${blurRadius}px ${color}`,
      },
    };
    changeFunction(event);
  };

  const handleBlurRadiusChange = (e) => {
    setBlurRadius(e.target.value);
    const event = {
      target: {
        name: refName,
        value: `${offsetX}px ${offsetY}px ${e.target.value}px ${color}`,
      },
    };
    changeFunction(event);
  };

  const handleColorChange = (newColor) => {
    setColor(newColor);
    const event = {
      target: {
        name: refName,
        value: `${offsetX}px ${offsetY}px ${blurRadius}px ${newColor}`,
      },
    };
    changeFunction(event);
  };

  const handleToggleChange = () => {
    setIsEditable(!isEditable);
    const event = {
      target: {
        name: refName,
        value: !isEditable ? `${offsetX}px ${offsetY}px ${blurRadius}px ${color}` : 'none',
      },
    };
    changeFunction(event);
  };

  return (
    <EditorBorder text={text}>
      <div className="flex justify-between items-center mb-2">
        <span className="text-xs font-medium text-gray-900">Enable Shadow</span>
        <input
          className='rounded'
          type="checkbox"
          checked={isEditable}
          onChange={handleToggleChange}
        />
      </div>
      <div className="flex space-x-4">
        <div className="w-1/4 ml-1">
          <label className="block text-xs font-medium text-gray-900">Color</label>
          <ColorPickerInput 
            color={color} 
            onChange={handleColorChange} 
            pickerPlacement={pickerPlacement}
            disabled={!isEditable}  
          />
        </div>
        <div className="w-1/4">
          <label className="block text-xs font-medium text-gray-900">Offset X</label>
          <input
            type="range"
            min="-8"
            max="8"
            step="0.1"
            value={offsetX}
            onChange={handleOffsetXChange}
            className="w-full"
            disabled={!isEditable}
          />
        </div>
        <div className="w-1/4">
          <label className="block text-xs font-medium text-gray-900">Offset Y</label>
          <input
            type="range"
            min="-8"
            max="8"
            step="0.1"
            value={offsetY}
            onChange={handleOffsetYChange}
            className="w-full"
            disabled={!isEditable}
          />
        </div>
        <div className="w-1/4">
          <label className="block text-xs font-medium text-gray-900">Blur</label>
          <input
            type="range"
            min="0"
            max="50"
            step="0.1"
            value={blurRadius}
            onChange={handleBlurRadiusChange}
            className="w-full"
            disabled={!isEditable}
          />
        </div>
      </div>
    </EditorBorder>
  );
};

export default ShadowEditor;
