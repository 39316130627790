import React, { useEffect, useState } from 'react';
import Loader from '../utils/Loader';
import EditButtonWhatsapp from './sections/EditButtonWhatsapp';

export default function ProjectEditButtons(
  {
    editMasterData,
    setEditMasterData,
  }
) {

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (editMasterData) {
      setIsLoaded(true);
    }
  }, [editMasterData]);

  const renderButton = (button, index) => {
    switch (button.type) {
      case 'whatsapp':
        return( 
          <>
          <label className="block text-lg font-medium text-gray-900">
            WhatsApp Button
          </label>
          <EditButtonWhatsapp
            editMasterData={editMasterData} 
            setEditMasterData={setEditMasterData}
            index={index} />
          </>
          );
      default:
        return null;
    }
  };

  return (
    <>
      {editMasterData === undefined || !isLoaded ? (
        <Loader />
      ) : (
        <>
          {editMasterData.buttons.length > 0 && editMasterData.buttons.map((button, index) => (
          <div key={index}>
            <div className="rounded-md my-2 px-3 pb-1.5 pt-2.5">
            {renderButton(button, index)}
            </div>
          </div>
        ))}
        </>
      )}
    </>
  );
}
