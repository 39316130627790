import './App.css';
import Login from './components/login/Login';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './components/app/Dashboard';
import Menu from './components/product/components/Menu';
import { AuthProvider } from './contexts/AuthContext'
import SignUp from './components/login/SignUp';

export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
          <Routes>
            <Route path='/' Component={Login}></Route>
            <Route path='/login' Component={Login}></Route>
            <Route path='/signup' Component={SignUp}></Route>
            <Route path='/app/:subSection?/:projectId?' Component={Dashboard}></Route>
            <Route path='/product/preview/' Component={Menu}></Route>
          </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}
