import { Card, CardMedia} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledItemCard = styled(Card)(({ 
  cardheight,
  cardmargin,
  cardpadding,
  cardshadow,
  cardborderradius,
  cardborder,
}) => ({
  height: cardheight,
  margin: cardmargin,
  padding: cardpadding,
  boxShadow: cardshadow,
  borderRadius: cardborderradius,
  border: cardborder,
  overflow: 'hidden',
  whiteSpace: 'normal',
  alignItems: 'right',
  
  
}));

export const StyledCardMedia = styled(CardMedia)(({
  mediaheight,
  mediaWidth,
  mediaBorder,
  mediaBorderRadius,
  mediaBackgroundSize,
  mediamargin = '0'
}) => ({
  height: mediaheight,
  width: mediaWidth,
  borderRadius: mediaBorderRadius,
  border: mediaBorder,
  component: 'img',
  position: 'static',
  margin: mediamargin,
  flexShrink: 0,
  flexGrow: 0,
  backgroundSize: mediaBackgroundSize,

}));

export const StyledBannerCard = styled(Card)(({ 
  cardheight,
  cardmargin,
  cardpadding,
  cardshadow,
  cardborderradius,
  cardborder,
  cardoverflow,
  cardwhitespace,
  cardalignitems,
  carddisplay,
  cardjustifycontent
}) => ({
  height: cardheight,
  margin: cardmargin,
  padding: cardpadding,
  boxShadow: cardshadow,
  borderRadius: cardborderradius,
  border: cardborder,
  overflow: cardoverflow,
  whiteSpace: cardwhitespace,
  alignItems: cardalignitems,
  display: carddisplay,
  justifyContent: cardjustifycontent,
  
  
}));