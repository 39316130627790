import React from 'react';
import EditorBorder from './EditorBorder';

const RangeSlider = (
  {
    external = true,
    refName,
    changeFunction,
    text,
    value,
    min,
    max, 
    step
  }
) => {

  const splitNumberAndUnit = (str) => {
    if (!isNaN(str)) {
      const number = str;
      const unit = '';
      return { number, unit };
    }
    const pattern = /(^-?\d*\.?\d+)(\D+)$/;
    const matches = str.match(pattern);
  
    if (matches) {
      const number = parseFloat(matches[1]);
      const unit = matches[2];
      return { number, unit };
    }

    // Default return if no match
    return { number: str, unit: '' };
  };

  const { number, unit } = splitNumberAndUnit(value);

  const handleChange = (e) => {
    e.preventDefault()
    const newValue = e.target.value;
    const event = {
      target: {
          name: refName,
      },
    };
    changeFunction(event, unit ? `${newValue}${unit}` : newValue);
  };

  return (
    <EditorBorder text={text} external={external}>
      <input 
        type="range" 
        min={min}
        max={max}
        step={step}
        value={number}
        onChange={handleChange}
        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
      />
    </EditorBorder>
  );
}

export default RangeSlider;
