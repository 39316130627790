import React, { useState } from 'react';
import EditorBorder from './EditorBorder';


const PositionEditor = ({ 
  value = '8vh 0vw 0vh 86vw', 
  refName,
  changeFunction,
  text
}) => {

  const parseValue = (value) => {
    const pattern = /^(\d*\.?\d+)(\D+)$/;
    const [vert, none1, none2, hor] = value.split(' ');
    return {
      vert: vert.match(pattern)[1],
      hor: hor.match(pattern)[1]
    };
  };

  const { vert: vert, hor: hor } = parseValue(value);

  const [vertical, setVertical] = useState(vert);
  const [horizontal, setHorizontal] = useState(hor);

  const handleVerticalChange = (e) => {
    setVertical( e.target.value);
    const event = {
      target: {
        name: refName,
        value: `${e.target.value}vh 0vw 0vh ${horizontal}vw`,
      },
    };
    changeFunction(event);
  };

  const handleHorizontalChange = (e) => {
    setHorizontal( e.target.value);
    const event = {
      target: {
        name: refName,
        value: `${vertical}vh 0vw 0vh ${e.target.value}vw`,
      },
    };
    changeFunction(event);
  };

  return (
    <EditorBorder text={text}>
      <div className="flex space-x-10">
      <div className="w-1/2">
          <label className="block text-xs font-medium text-gray-900">Vertical Position</label>
          <input
            type="range"
            min="0"
            max="93"
            step="0.1"
            value={vertical}
            onChange={handleVerticalChange}
            className="w-full"
          />
        </div>
        <div className="w-1/2">
          <label className="block text-xs font-medium text-gray-900">Horizontal Position</label>
          <input
            type="range"
            min="0"
            max="88"
            step="0.1"
            value={horizontal}
            onChange={handleHorizontalChange}
            className="w-full"
          />
        </div>
      </div>
    </EditorBorder>
  );
};

export default PositionEditor;
