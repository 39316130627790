import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import { ScrollableCardContainer, ItemsContainer } from './styledItems/Containers'
import { HorizontalScrollableContent, VerticalScrollableContent} from './styledItems/ScrollableContent'
import { CategoryCard } from './SectionItems/CategoryCard';
import renderItem from './ItemContent/renderItem';

const Content = ({
  defaultSelectedSection,
  sections,
  onCardClick,
  backgroundColor="white",
  highlightSettings = "1px solid #000000",
  textSize = "1rem",
  fontFamily = "Roboto",
  textColor = "blue",
  cardContainerPadding= '2vh',
  cardContainerMarginTop= '16vh',
  cardContainerHeight= '14vh',
  cardContainerPosition= 'fixed',
  cardContainerBorderBottom= '1px solid #C0C0C0',

  cardRatio = (16 / 9), 
  cardHeight= '7vh',
  cardCursor= 'pointer',
  cardBorderRadius= '8%',
  cardTransition= 'box-shadow 0.25s ease-in-out, border 0.25s ease-in-out',
  cardMarginRight="5vw",
  cardPaddingRight="1vw",

  selectedShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px',
  standingShadow='rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',

  contentMarginTop='30vh',
  contentHeight='78vh'

}) => {

  const [selectedCard, setSelectedCard] = useState(null);
  const [displayItems, setDisplayItems] = useState([])

  useEffect(() => {
    //console.log(defaultSelectedSection)
    //console.log(sections[0])
    if(selectedCard !== null && sections[selectedCard]?.isActive){
      setDisplayItems(sections[selectedCard]?.items)
    }else if(sections.length === 0){
      setSelectedCard(undefined);
      setDisplayItems(undefined)
    }else if(sections[defaultSelectedSection].isActive){
      setSelectedCard(defaultSelectedSection);
      setDisplayItems(sections[defaultSelectedSection]?.items)
    }else{
      for(let i = 0; i < sections.length; i++){
        if(sections[i].isActive){
          setSelectedCard(i);
          setDisplayItems(sections[i]?.items)
          break;
        }
      }
    }
    
  }, [defaultSelectedSection, sections]); 


  // Efecto para rerenderizado al cambio de un item en en editor. 

  const handleCardSelection = (event, section, index) => {
    console.log("Hola")
    event.preventDefault();
    setSelectedCard(index);
    setDisplayItems(sections[index].items)
    onCardClick(section, index);
  };

  const handleWheelHorizontalScroll = (event) => {
    const container = event.currentTarget;
    container.scrollLeft += event.deltaY/2
  };

  const onWheel = useCallback(
    (e) => {
      e.preventDefault();
    },
    [],
  );

  const divRefCallback = useCallback(
    (node) => {
      //console.log(node);
      if (node == null) {
        return;
      }
      node.addEventListener('wheel', onWheel, { passive: false });
    },
    [onWheel],
  );

  //console.log(displayItems)
  //console.log(Object.keys(displayItems).length > 0)

  return (
    <>
    <Box /* style={{paddingRight: '10px'}} */> 
      <ScrollableCardContainer 
        ref={divRefCallback}
        backgroundColor={backgroundColor}
        cardContainerPadding={cardContainerPadding}
        cardContainerMarginTop={cardContainerMarginTop}
        cardContainerHeight={cardContainerHeight}
        cardContainerPosition={cardContainerPosition}
        cardContainerBorderBottom={cardContainerBorderBottom}
      >
        <HorizontalScrollableContent onWheel={handleWheelHorizontalScroll}>
          <CategoryCard
            sections = {sections}
            cardMarginRight = {cardMarginRight}
            cardPaddingRight ={cardPaddingRight}
            handleCardSelection = {handleCardSelection}
            selectedCard = {selectedCard}
            highlightSettings= {highlightSettings}
            cardHeight = {cardHeight}
            cardRatio = {cardRatio}
            cardCursor = {cardCursor}
            cardBorderRadius ={cardBorderRadius}
            cardTransition = {cardTransition}
            selectedShadow = {selectedShadow}
            standingShadow = {standingShadow}
            textSize ={textSize}
            fontFamily ={fontFamily}
            textColor ={textColor}
          />
        </HorizontalScrollableContent>
      </ScrollableCardContainer>
      <ItemsContainer
        contentMarginTop = {contentMarginTop}
        contentHeight = {contentHeight}
      >
        <VerticalScrollableContent>
            {displayItems && Object.keys(displayItems).length > 0 ? (
              displayItems.map((item, index) => (
                renderItem(item, index)
              ))
            ) : (<></>)}
        </VerticalScrollableContent>
      </ItemsContainer>
      </Box>
    </>
    
  );
};

export default Content;
